import { Settings } from "p6m-p6u";
import { GoodLuckNames, SettingPosition, TestVersionName } from "../enums/settings";

export const SettingsDefault: Settings = {
    header: {
        class: {
            show: true,
            value: "",
        },
        name: {
            show: true,
        },
        date: {
            show: true,
            value: 0,
        },
        abGroup: {
            show: false,
            value: TestVersionName.A,
        },
    },
    titleAndTopics: {
        title: {
            show: true,
        },
        vocabularyTopics: {
            show: false,
        },
    },
    gradingAndSignatures: {
        average: {
            show: false,
            position: SettingPosition.TOP,
        },
        overallScore: {
            show: true,
            position: SettingPosition.BOTTOM,
        },
        grade: {
            show: false,
            position: SettingPosition.TOP,
        },
        gradingScale: {
            show: false,
            position: SettingPosition.TOP,
            scale: [
                { grade: 1, minScorePercentage: 90 },
                { grade: 2, minScorePercentage: 70 },
                { grade: 3, minScorePercentage: 50 },
                { grade: 4, minScorePercentage: 30 },
                { grade: 5, minScorePercentage: 10 },
                { grade: 6, minScorePercentage: 0 },
            ],
        },
        parentSignature: {
            show: false,
            position: SettingPosition.BOTTOM,
        },
        teacherSignature: {
            show: false,
            position: SettingPosition.BOTTOM,
        },
    },
    additional: {
        goodLuck: {
            show: true,
            value: GoodLuckNames.SIDNEY_THUMBS_UP,
            position: SettingPosition.BOTTOM,
        },
    },
};

export const ConfigurationStorageKey = "configuration";
