import React, { useEffect, useState } from "react";
import { CreationContext } from "p6m-contexts";
import {
    AvailableUserOwnSubjectsInfo,
    BandCards,
    BandUnit,
    ExerciseCreationModel,
    GetBandInfoResponse,
    Settings,
    ReducedTestDetails,
    SelectedBookData,
    SidebarSelectedWordsList,
    StartPageRedirectionTarget,
    StudentTestContent,
    StudentTestContentWrapper,
    StudentTestSharingData,
    TeacherTestDetails,
    TestQuestionModel,
    UnitCard,
    UserSubject,
    UserUnit,
    UserUnitCards,
    VerbtrainingTensesResponseItem,
} from "p6m-p6u";
import { useGeneralContext } from "./AppGeneralContext";
import { useCookies } from "react-cookie";
import { logEventWithProps } from "../logging/Logger";
import { useHistory } from "react-router-dom";
import { useT } from "@transifex/react";
import TagManager from "react-gtm-module";
import { cp } from "../config";
import {
    deleteUserV1TestByName,
    getCardsForUnit,
    getTeacherSharedTests,
    getUserTests,
    getUserV1Tests,
} from "../networking/tests";
import { NetworkErrorCodes } from "../networking/errorCodes";
import verbtrainingTenses from "../assets/verbtraining/verbtrainingTenses.json";

import {
    deleteRequest,
    getRequest,
    postRequest,
    processCardsForUnitResponse,
    putRequest,
    sortAndFilterUnitsForBandResponse,
} from "../helpers/networkHelper";
import {
    composeBookName,
    getContentDetailsAndLoggingDataFromTest,
    reduceTestContent,
    ContentDetailsAndLoggingDataParams,
} from "../helpers/TestPreparationHelper";
import { redirectAnonymousUser } from "../helpers/TestSavingHelper";
import { checkContentForErrors, removeAnswersFromTest } from "../helpers/ShareTest";
import { processUserUnitCardsResponse } from "../helpers/UserExercises";
import {
    generateExerciseId,
    getExerciseKeyFromExerciseName,
    translateWordFromQuestionToModel,
    updateWordsInProgressDirection,
} from "../helpers/TestCreation";
import { getStandardOrDefaultConfiguration } from "../helpers/PDFHelper";

enum PXPErrorClasses {
    "Unauthorized" = "de.phase6.p6p.server.core.exception.UnauthorizedRequestException",
}

const TestCreationContext = React.createContext<CreationContext | undefined>(undefined);

function useTestCreationContext() {
    const context = React.useContext(TestCreationContext);

    if (!context) {
        throw new Error("useTestCreationContext must be used inside the provider");
    }
    const { userId, setIsDataBeingLoaded, isIframeMode, creatingMode, setCreatingMode, makePxpRequest, userInfo } =
        useGeneralContext();
    const [cookies] = useCookies(["GDPR", "JOSSO_SESSIONID"]);
    const history = useHistory();

    useEffect(() => {
        if (cookies && cookies.GDPR && cookies.GDPR.includes("analytics") && !window.dataLayer) {
            TagManager.initialize({
                gtmId: `${cp.cfg.REACT_APP_GTM_ID}`,
                auth: `${cp.cfg.REACT_APP_GTM_AUTH}`,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cookies]);

    const t = useT();

    const t_errorInsertingTest = t("There was an error while creating the new Test.", {
        _tags: "CreateTest,CreateClass",
    });
    const t_errorUpdatingTest = t("There was an error while updating the new Test.", {
        _tags: "CreateTest,CreateClass",
    });
    const t_errorSavingAnonymousTest = t("There was an error while saving your new Test.", {
        _tags: "CreateTest,CreateClass,AnonymousTest",
    });
    const t_errorWhileDeletingTest = t("There was an error while deleting the Test.", {
        _tags: "CreateTest,CreateClass",
    });
    const t_errorUpdatingStudentTest = t("There was an error while updating this test.", {
        _tags: "CreateTest,CreateClass",
    });
    const t_noTestFound = t("Couldn't find the test.", {
        _tags: "CreateTest,CreateClass",
    });
    const t_testHasNoContent = t("Test has no content", {
        _tags: "CreateTest,CreateClass",
    });

    const {
        setDataLoaded,
        availableVerbtrainingTenses,
        studentTestContentWrapper,
        setStudentTestContentWrapper,
        setSelectedBookData,
        vocabularyDrawerContent,
        setVocabularyDrawerContent,
        setSelectedBandUnits,
        selectedBookData,
        availableCards,
        setAvailableCards,
        maxAmountOfCardsSelectable,
        setIsWarningModalVisible,
        setWarningModalContent,
        testContent,
        setTestContent,
        testName,
        currentTestId,
        setCurrentTestId,
        userTests,
        currentExerciseModel,
        setCurrentExerciseModel,
        settings,
        setSettings,
        showScores,
        setShowScores,
        setTestName,
        setVtgV1Tests,
        setIsTestBeingEdited,
        setPreviouslyUsedCardsIds,
        setIsTestSharingInfoBeingLoaded,
        setUserTests,
        dataLoaded,
        setUserOwnSubjects,
        setUserOwnSubjectUnits,
        userUnitCards,
        setUserUnitCards,
        selectedUserSubject,
        setSelectedUserSubject,
        userOwnSubjects,
        setUserUsedOwnSubjectsInfo,
        userUsedOwnSubjectsInfo,
        setShouldSubjectSelectionBeDisabled,
        setUnitsToPreloadIds,
    } = context;

    async function deleteTest(testId: string) {
        try {
            await deleteRequest(`aea/tests/${testId}/`);

            if (canLogEvent()) {
                logEventWithProps("Vokabeltest v2 Deleted", {
                    testId,
                });
            }
            setDataLoaded(false);
            loadUserTests(true);
            return Promise.resolve("success");
        } catch (e) {
            console.log(e);
            openWarningModal(t_errorWhileDeletingTest);
            return Promise.reject(e);
        }
    }

    async function duplicateTest(testId: string, testName: string) {
        try {
            // Step 1: Get the original test data.
            const baseTestDetails: TeacherTestDetails = userTests.find((t) => t.id === testId);

            if (baseTestDetails) {
                const { id, updatedDate, createdDate, ...testContent } = baseTestDetails;

                // Step 2: Create a copy of that data, and get the new id.
                const copyTestResult = await postRequest("aea/tests/", {
                    ...testContent,
                    name: testName,
                });

                const newTestId = copyTestResult.data.id;

                if (canLogEvent()) {
                    logEventWithProps("Vokabeltest v2 Duplicated", {
                        newTestId,
                        duplicatedTestId: testId,
                    });
                }

                setDataLoaded(false);
                await loadUserTests(true);
                return Promise.resolve("success");
            } else {
                throw new Error("Test not found");
            }
        } catch (e) {
            return Promise.reject(e);
        }
    }

    /**
     * Creates the test data for the preview based on the main context's data
     * as the preview uses a special format of test
     * @returns a StudentTestContent, a teacher test with the answers removed
     */
    function createPreviewData() {
        const testToUse = [...testContent];

        const answerFreeTestContent = removeAnswersFromTest(testToUse, availableVerbtrainingTenses);
        if (answerFreeTestContent.length === 0) {
            // nothing to preview
            return {};
        }

        const bookName = `${selectedBookData.publisherBook?.BookName} (${selectedBookData.publisherBook?.PublisherName}) (${selectedBookData.band?.ShortName})`;

        const testToStore: StudentTestContent = {
            content: answerFreeTestContent,
            dueDate: undefined,
            teacherName: userInfo.userName,
            teacherComment: "",
            timeLimit: "",
            showScores,
            settings,
            name: testName,
            updatedDate: new Date().getTime(),
            createdDate: new Date().getTime(),
            bookInfo: {
                name: bookName,
                imagePath: selectedBookData.band?.Image ?? "",
            },
            ownSubjectInfo: {
                name: selectedUserSubject?.name,
                primaryLang: selectedUserSubject?.primaryLang,
                secondaryLang: selectedUserSubject?.secondaryLang || "",
                id: selectedUserSubject?.id,
                ownerId: selectedUserSubject?.ownerId,
            },
            language: selectedBookData.band?.Language,
            articleId: selectedBookData.band?.ID,
            id: currentTestId || `preview${new Date().getTime()}`,
        };

        return testToStore;
    }

    async function updateSharedTestProperties(testId: string, sharingData: StudentTestSharingData) {
        const testToShare = studentTestContentWrapper[testId];

        if (!testToShare) {
            openWarningModal(t_noTestFound);
            return Promise.reject();
        }
        if (!testToShare.content) {
            openWarningModal(t_testHasNoContent);
            return Promise.reject();
        }

        try {
            const updatedTest = {
                ...testToShare,
                dueDate: sharingData.dueDate,
            };

            await putRequest(`aea/student-tests/${testId}/`, {
                ...updatedTest,
            });

            const allTests = { ...studentTestContentWrapper };
            allTests[testId] = {
                ...updatedTest,
                id: testId,
            };
            setStudentTestContentWrapper(allTests);

            if (canLogEvent()) {
                logEventWithProps("VTG - Assignment Properties Updated", {
                    testId: testId,
                    assignmentDeadline: sharingData.dueDate,
                    assignmentTime: sharingData.timeLimit,
                });
            }
        } catch (e) {
            console.log(e);
            openWarningModal(t_errorUpdatingStudentTest);
            return Promise.reject();
        }
    }

    async function shareTest(testId: string, testInfo: StudentTestSharingData) {
        const testToShare = userTests.find((t) => t.id === testId);

        if (!testToShare) {
            openWarningModal(t_noTestFound);
            return Promise.reject();
        }
        if (!testToShare.content) {
            openWarningModal(t_testHasNoContent);
            return Promise.reject();
        }

        try {
            const testToShareCopy = { ...testToShare };
            const contentErrors = checkContentForErrors(testToShareCopy.content);
            if (contentErrors.length > 0) {
                return Promise.reject({ contentErrors });
            }

            const answerFreeTestContent = removeAnswersFromTest(testToShareCopy.content, availableVerbtrainingTenses);
            testToShareCopy.content = answerFreeTestContent;

            const { dueDate, teacherComment, timeLimit } = testInfo;
            const testDueDate = (dueDate === 0 ? "" : dueDate) || "";
            const testConditions = {
                dueDate: testDueDate,
                teacherName: userInfo.userName,
                teacherComment: teacherComment || "",
                timeLimit: timeLimit || "",
            };

            const testToSave = {
                ...testToShareCopy,
                ...testConditions,
            };

            await postRequest(`aea/student-tests/${testId}/`, { ...testToSave });
            const updatedTestContentWrapper = { ...studentTestContentWrapper };
            updatedTestContentWrapper[testId] = { ...testToSave, id: testId };
            setStudentTestContentWrapper(updatedTestContentWrapper);
        } catch (e) {
            return Promise.reject(e);
        }
    }

    function canLogEvent() {
        return cookies && cookies.GDPR && cookies.GDPR.includes("analytics");
    }

    const createBasicTestData = (
        currentDate: Date,
        bookCardsCount: number,
        bookName: string,
        ownCardsCount: number
    ): TeacherTestDetails => {
        let basicTestData: any = {
            name: testName,
            updatedDate: currentDate,
            createdDate: currentDate,
            showScores,
            settings,
        };

        if (bookCardsCount) {
            basicTestData = {
                articleId: selectedBookData.band?.ID,
                language: selectedBookData.band?.Language,
                bookInfo: {
                    name: bookName,
                    imagePath: selectedBookData.band?.Image,
                },
                ...basicTestData,
            };
        }

        if (ownCardsCount) {
            basicTestData = {
                ownSubjectInfo: {
                    name: selectedUserSubject.name,
                    primaryLang: selectedUserSubject.primaryLang,
                    secondaryLang: selectedUserSubject?.secondaryLang || "",
                    id: selectedUserSubject.id,
                    ownerId: selectedUserSubject.ownerId,
                },
                ...basicTestData,
            };
        }

        return basicTestData;
    };

    const prepareReducedTestDetails = (
        newTestContent: TestQuestionModel[]
    ): ContentDetailsAndLoggingDataParams & { reducedTestDetails: ReducedTestDetails } => {
        let testId = currentTestId.trim();
        const currentDate = new Date();
        const reducedTestContent = reduceTestContent(newTestContent);
        const bookName = composeBookName(selectedBookData);

        const { ownCardsCount, bookCardsCount, loggingContent, loggingContentExtendedBookInformation, amplitudeData } =
            getContentDetailsAndLoggingDataFromTest(testId, reducedTestContent, selectedBookData);

        let basicTestData = createBasicTestData(currentDate, bookCardsCount, bookName, ownCardsCount);

        const reducedTestDetails = {
            ...basicTestData,
            content: reducedTestContent,
        };

        return {
            reducedTestDetails,
            ownCardsCount,
            bookCardsCount,
            loggingContent,
            loggingContentExtendedBookInformation,
            amplitudeData,
        };
    };

    /**
     * @param newTestContent contains the exercises
     * @returns bundled test details from the context, INCLUDING the id if present
     */
    const prepareFullTestDetails = (newTestContent: TestQuestionModel[]) => {
        const { reducedTestDetails } = prepareReducedTestDetails(newTestContent);
        const fullTestDetails = { ...reducedTestDetails, id: currentTestId };

        return fullTestDetails;
    };

    async function saveTest(newTestContent: TestQuestionModel[], sendEvent = false, openInNewTab?: boolean) {
        const isAnonymous = userInfo.isAnonymousUser;

        if (!userId && !isAnonymous) {
            return Promise.reject("isAnonymous user or not userId set");
        }

        let testId = currentTestId.trim();
        const savingMode = testId.length > 0 ? "UPDATE" : "ADD";

        // prepare test data first - - - - - - - - - - - - - - - - - - - - - - - - -

        const { reducedTestDetails, loggingContent, loggingContentExtendedBookInformation, amplitudeData } =
            prepareReducedTestDetails(newTestContent);

        const testRequestContent = reducedTestDetails;

        const possibleWarningText = isAnonymous
            ? t_errorSavingAnonymousTest
            : savingMode === "ADD"
            ? t_errorInsertingTest
            : t_errorUpdatingTest;
        const possiblePromiseRejectText = isAnonymous
            ? "Error while saving anonymous test. "
            : `Error when ${savingMode === "ADD" ? "saving" : "updating"} test. `;

        if (isAnonymous) {
            setIsDataBeingLoaded(true); // loading screen while saving
        }

        // save - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
        if (savingMode === "ADD") {
            try {
                const addedTestResponse = await postRequest("aea/tests/", { ...testRequestContent });
                const testId = addedTestResponse.data.id;
                setCurrentTestId(testId);
            } catch (e) {
                if (isAnonymous) setIsDataBeingLoaded(false);
                openWarningModal(possibleWarningText);
                return Promise.reject(possiblePromiseRejectText + e);
            }

            if (!isAnonymous && sendEvent && canLogEvent()) {
                logEventWithProps("Vokabeltest v2 Saved", { ...loggingContentExtendedBookInformation, testId });
            }
        } else {
            if (!isAnonymous) {
                const currentTest = userTests.find((t) => t.id === testId);
                testRequestContent.createdDate = currentTest ? currentTest.createdDate : new Date();
            }

            try {
                await putRequest(`aea/tests/${testId}/`, { ...testRequestContent });
            } catch (e) {
                if (isAnonymous) setIsDataBeingLoaded(false);
                openWarningModal(possibleWarningText);
                return Promise.reject(possiblePromiseRejectText + e);
            }

            if (!isAnonymous && sendEvent && canLogEvent()) {
                logEventWithProps("Vokabeltest v2 Edited", loggingContent);
            }
        }

        if (isAnonymous) {
            localStorage.setItem("anonymousTestId", testId); // will be used to save test to account once logged in
            localStorage.setItem("amplitudeData", JSON.stringify(amplitudeData));

            redirectAnonymousUser(isIframeMode, openInNewTab);
            setIsDataBeingLoaded(false);
        }
    }

    async function checkForAnonymousTests() {
        const anonymousTestId = localStorage.getItem("anonymousTestId") || "";

        if (anonymousTestId !== "" && userId) {
            // setting this to avoid triggering the redirect to the select content screen.
            setDataLoaded(true);
            setIsDataBeingLoaded(true);

            try {
                const testContentResponse = await getRequest(`aea/tests/${anonymousTestId}/`);
                const anonymousTestContent = testContentResponse.data;
                if (!anonymousTestContent.content) {
                    openWarningModal(t_testHasNoContent);
                    localStorage.removeItem("anonymousTestId");
                    setIsDataBeingLoaded(false);
                    return;
                }

                // Updating test with new user id.
                await putRequest(`aea/tests/${anonymousTestId}/`, { ...anonymousTestContent });
                const amplitudeData = JSON.parse(localStorage.getItem("amplitudeData") || "{}");

                if (amplitudeData.hasOwnProperty("testId") && canLogEvent()) {
                    logEventWithProps("Vokabeltest v2 Saved", { ...amplitudeData });
                }

                setDataLoaded(false);
                localStorage.removeItem("anonymousTestId");
            } catch (e) {
                openWarningModal(t_errorSavingAnonymousTest);
                localStorage.removeItem("anonymousTestId");
            }

            loadUserTests(true);
            setIsDataBeingLoaded(false);
        }
    }

    async function loadVocabularyForBand(bandId: string) {
        getRequest("p6u/get-units-for-band/", { bandId }).then((res) => {
            if (res.data && res.data.length > 0) {
                //Hide hidden and sort units by order_id
                setSelectedBandUnits(sortAndFilterUnitsForBandResponse(res.data));
            } else {
                alert("error getting units");
            }
        });
    }

    async function loadCardsForUnits(unitIds: string[]) {
        const updatedCards: BandCards = {};

        for (let unitId of unitIds) {
            try {
                const unitContentResponse = await getCardsForUnit(unitId);
                if (unitContentResponse) {
                    const newCardsList: Array<UnitCard> = processCardsForUnitResponse(unitId, unitContentResponse);
                    updatedCards[unitId] = {
                        count: unitContentResponse.count,
                        items: newCardsList,
                    };
                }
            } catch (e) {
                console.log("error loading unit " + unitId);
            }
        }

        setAvailableCards(updatedCards);
        return Promise.resolve();
    }

    function loadCardsForUnit(unitId: string) {
        const requestParams: any = { unitId: unitId };

        if (userId) {
            requestParams["userId"] = userId;
        }

        getCardsForUnit(unitId).then(
            (res) => {
                const currentCards = { ...availableCards };
                if (res) {
                    const newCardsList: Array<UnitCard> = processCardsForUnitResponse(unitId, res);
                    currentCards[unitId] = {
                        count: res.count,
                        items: newCardsList,
                    };
                }
                setAvailableCards(currentCards);
                setIsDataBeingLoaded(false);
            },
            () => {
                setIsDataBeingLoaded(false);
            }
        );
    }

    function removeCardFromSelectedWords(wordId: string) {
        const words = { ...vocabularyDrawerContent };
        const wordToDelete = words[wordId];

        //Remove card from exercises.
        if (wordToDelete.exerciseIds) {
            const testContentCopy = [...testContent];
            for (let i = 0; i < testContentCopy.length; i++) {
                const testItem = { ...testContentCopy[i] };
                if (
                    testItem.exerciseId &&
                    wordToDelete.exerciseIds.includes(testItem.exerciseId) &&
                    testItem.selectedWords
                ) {
                    testItem.selectedWords = testItem.selectedWords.filter((w) => {
                        if ("id" in w) {
                            return String(w.id) !== String(wordId);
                        } else if ("ID" in w) {
                            return String(w.ID) !== String(wordId);
                        }
                        return false;
                    });
                    testContentCopy[i] = testItem;
                }
            }
            setTestContent(testContentCopy);
        }
        delete words[wordId];
        setVocabularyDrawerContent(words);
    }

    function removeCardsFromSelectedWords(wordIds: Array<string>) {
        const words = { ...vocabularyDrawerContent };
        let exercisesCopy = [...testContent];

        const exercisesWithWordsToDelete: { [id: string]: Array<string> } = {};

        // Deleting words from the drawer, adding the exercises to a list of exercises and words
        // that needs to be removed.
        wordIds.forEach((w) => {
            const wordContent = words[w];
            if (wordContent) {
                if (wordContent.exerciseIds && wordContent.exerciseIds.length > 0) {
                    wordContent.exerciseIds.forEach((eId) => {
                        if (exercisesWithWordsToDelete.hasOwnProperty(eId)) {
                            if (!exercisesWithWordsToDelete[eId].includes(w)) {
                                exercisesWithWordsToDelete[eId].push(w);
                            }
                        } else {
                            exercisesWithWordsToDelete[eId] = [w];
                        }
                    });
                }
                delete words[w];
            }
        });

        const exercisesToDelete: Array<string> = [];

        for (let i = 0; i < exercisesCopy.length; i++) {
            const exercise = { ...exercisesCopy[i] };
            if (!exercise || !exercise.exerciseId) {
                return;
            }

            // If the exercise has pending words to delete, delete them, amd if its empty, put it on list to delete.
            if (Object.keys(exercisesWithWordsToDelete).includes(exercise.exerciseId) && exercise.selectedWords) {
                exercise.selectedWords =
                    exercise.selectedWords.filter((w) => {
                        if ("id" in w && w.id) {
                            return !exercisesWithWordsToDelete[exercise.exerciseId!].includes(w.id);
                        } else if ("ID" in w && w.ID) {
                            return !exercisesWithWordsToDelete[exercise.exerciseId!].includes(w.ID);
                        }
                        return false;
                    }) || [];

                if (exercise.selectedWords.length === 0) {
                    exercisesToDelete.push(exercise.exerciseId);
                }
            }
            exercisesCopy[i] = exercise;
        }

        if (exercisesToDelete.length > 0) {
            exercisesCopy = exercisesCopy.filter((e) => {
                return e.exerciseId && !exercisesToDelete.includes(e.exerciseId);
            });
        }

        setVocabularyDrawerContent(words);
        setTestContent(exercisesCopy);
    }

    function addCardToSelectedWords(card: UnitCard): boolean {
        const words = { ...vocabularyDrawerContent };
        if (Object.keys(words).length >= maxAmountOfCardsSelectable) {
            return false;
        }
        if (!words[card.id!]) {
            words[card.id!] = {
                wordContent: card,
                isInMoreThanOneExercise: false,
                exerciseIds: [],
            };
            setVocabularyDrawerContent(words);
        }
        return true;
    }

    function addCardsToSelectedWords(cards: Array<UnitCard>): "SUCCESS" | "CANT_ADD" | "INCOMPLETE_ADD" {
        const words = { ...vocabularyDrawerContent };

        if (Object.keys(words).length >= maxAmountOfCardsSelectable) {
            return "CANT_ADD";
        }

        let response: "SUCCESS" | "CANT_ADD" | "INCOMPLETE_ADD" = "SUCCESS";

        for (let i = 0; i < cards.length; i++) {
            const card = cards[i];
            if (words[card.id!]?.wordContent?.id) {
                continue;
            }
            words[card.id!] = {
                wordContent: card,
                isInMoreThanOneExercise: false,
                exerciseIds: [],
            };
            if (Object.keys(words).length >= maxAmountOfCardsSelectable) {
                response = i === cards.length - 1 ? "SUCCESS" : "INCOMPLETE_ADD";
                break;
            }
        }

        setVocabularyDrawerContent(words);
        return response;
    }

    async function loadUserOwnSubjectUUIDsInfoIntoState(subjectUUIDS: Array<string>) {
        setIsDataBeingLoaded(true);
        const subjectsInfo: AvailableUserOwnSubjectsInfo = { ...userUsedOwnSubjectsInfo };

        for (let i = 0; i < subjectUUIDS.length; i++) {
            const subjectUuid = subjectUUIDS[i];
            if (subjectsInfo.hasOwnProperty(subjectUuid) && subjectsInfo[subjectUuid].id) {
                continue;
            }
            try {
                const userSubjectsResponse = await makePxpRequest("GET", `/${userId}/subjects/${subjectUuid}`);
                if (userSubjectsResponse.data.replyContent) {
                    subjectsInfo[subjectUuid] = {
                        ...userSubjectsResponse.data.replyContent,
                        id: subjectUuid,
                    };
                } else {
                    throw new Error("No data was loaded");
                }
            } catch (e) {
                setIsDataBeingLoaded(false);
                return Promise.reject(e);
            }
        }
        setIsDataBeingLoaded(false);
        setUserUsedOwnSubjectsInfo({ ...subjectsInfo });
        return subjectsInfo;
    }

    function openWarningModal(content: React.ReactNode) {
        setWarningModalContent(content);
        setIsWarningModalVisible(true);
    }

    function updateCurrentExerciseProperties(newProperties: ExerciseCreationModel) {
        const updatedExercise = { ...currentExerciseModel, ...newProperties };

        if (
            typeof newProperties.direction !== "undefined" &&
            currentExerciseModel.direction !== newProperties.direction &&
            updatedExercise.wordsInProgress
        ) {
            updatedExercise.wordsInProgress = updateWordsInProgressDirection(
                updatedExercise.wordsInProgress,
                newProperties.direction
            );
        }

        setCurrentExerciseModel(updatedExercise);
    }

    /**
     * Loads the test data into the main context (TextCreationContext)
     * This is used for editing tests, the preview and configuration
     * @param testId the id of the test to load
     * @param checkContent checks the content for errors
     * -> prevents opening previews / configurations with broken content
     */
    async function loadTestForEdition(testId: string, checkContent?: boolean) {
        setIsDataBeingLoaded(true);
        setCurrentTestId(testId);

        const localTestData: TeacherTestDetails = userTests.find((t: TeacherTestDetails) => t.id === testId);

        if (!localTestData) {
            openWarningModal(t_noTestFound);
            return;
        }

        if (!localTestData.content) {
            openWarningModal(t_testHasNoContent);
            return;
        }

        const newTestContent: Array<TestQuestionModel> = [];
        const newDrawerContent: SidebarSelectedWordsList = {};

        localTestData.content?.forEach((exercise) => {
            const newExerciseData: TestQuestionModel = { ...exercise };

            // If the exercise type is unknown, then skip the exercise, otherwise generate an id.
            if (!newExerciseData.exerciseId) {
                const exerciseKey = getExerciseKeyFromExerciseName(exercise.questionMode || "");
                if (exerciseKey) {
                    newExerciseData.exerciseId = generateExerciseId(exerciseKey, testContent);
                } else {
                    return;
                }
            }

            if (!newExerciseData.hasOwnProperty("automaticallyCreated")) {
                newExerciseData.automaticallyCreated = false;
            }

            newTestContent.push(newExerciseData);

            newExerciseData.selectedWords?.forEach((w) => {
                if ("id" in w && w.id) {
                    if (newDrawerContent.hasOwnProperty(w.id)) {
                        newDrawerContent[w.id].exerciseIds!.push(newExerciseData.exerciseId!);
                        newDrawerContent[w.id].isInMoreThanOneExercise = true;
                    } else {
                        newDrawerContent[w.id] = {
                            wordContent: translateWordFromQuestionToModel(w),
                            isInMoreThanOneExercise: false,
                            exerciseIds: [newExerciseData.exerciseId!],
                        };
                    }
                }
            });
        });

        const standardOrDefaultSettings = getStandardOrDefaultConfiguration().settings;

        if (checkContent) {
            const contentErrors = checkContentForErrors(newTestContent);
            if (contentErrors.length > 0) {
                setCurrentTestId("");
                setTestContent([]);
                setTestName("");
                setShowScores(false);
                setSettings(standardOrDefaultSettings);
                setIsDataBeingLoaded(false);
                return Promise.reject({ contentErrors });
            }
        }

        setTestContent(newTestContent);
        setTestName(localTestData.name || "");
        setShowScores(localTestData.showScores ?? false);
        setSettings(localTestData.settings ?? standardOrDefaultSettings);

        try {
            if (localTestData.articleId) {
                await loadInitArticleIdData(localTestData.articleId);
            }
            if (localTestData.ownSubjectInfo && localTestData.ownSubjectInfo.id) {
                const ownSubjectsResponse = await loadUserOwnSubjectUUIDsInfoIntoState([
                    localTestData.ownSubjectInfo.id,
                ]);
                if (ownSubjectsResponse) {
                    const userSubject = ownSubjectsResponse[localTestData.ownSubjectInfo.id];
                    setSelectedUserSubject(userSubject);
                    setShouldSubjectSelectionBeDisabled(true);
                }
            }
        } catch (e) {
            setCurrentTestId("");
            setTestContent([]);
            setTestName("");
            setShowScores(false);
            setSettings(standardOrDefaultSettings);
            setIsDataBeingLoaded(false);
            return Promise.reject(e);
        }

        setIsTestBeingEdited(true);
        setCreatingMode("TEST");

        setVocabularyDrawerContent(newDrawerContent);
        setIsDataBeingLoaded(false);
    }

    // V1 tests are deprecated and can't be created anymore, but some teachers still have them and might need data from them.
    // (will hopefully be deleted in the future)
    async function deleteV1Test(testName: string) {
        setIsDataBeingLoaded(true);
        // Load v1 tests
        const deletionSuccessful = await deleteUserV1TestByName(testName);

        if (deletionSuccessful) {
            // Load v1 tests
            const v1Tests = await getUserV1Tests();
            setVtgV1Tests(v1Tests);
        }
        setIsDataBeingLoaded(false);
    }

    // V1 tests are deprecated and can't be created anymore, but some teachers still have them and might need data from them.
    // (will hopefully be deleted in the future)
    function downloadV1Test(testName: string) {
        let downloadUrl = cp.cfg.REACT_APP_VTG_SITE + "get-pdf.jsp?file=" + testName;
        if (userInfo.isMobileApp) {
            // downloadUrl += "&JossoSessionID=" + this.autoLoginSessionId + "&teacherId=" + this.teacherID;
            downloadUrl += "&teacherId=" + userId;
        }
        downloadUrl += "&type=.pdf";
        window.open(downloadUrl);
    }

    async function loadInitArticleIdData(
        articleId: string,
        isUuid: boolean = false,
        shouldSetSelectedBookData: boolean = true
    ) {
        const requestParameters: { bandId: string; isUuid?: boolean } = {
            bandId: articleId,
        };
        if (isUuid) {
            requestParameters.isUuid = true;
        }
        const bandDataResponse = await getRequest("p6u/get-band-info/", requestParameters);
        if (!bandDataResponse || !bandDataResponse.data) {
            return Promise.reject(new Error("No data was loaded"));
        }
        const bandData: GetBandInfoResponse = bandDataResponse.data;
        if (shouldSetSelectedBookData) {
            setSelectedBookData({ ...bandData });
        }
        return Promise.resolve(bandData);
    }

    async function loadUnitsForBand(bandId: string): Promise<BandUnit[]> {
        const unitsDataResponse = await getRequest("p6u/get-units-for-band/", { bandId });
        if (!unitsDataResponse || !unitsDataResponse.data) {
            return Promise.reject(new Error("No data was loaded"));
        }

        const sortedUnits = sortAndFilterUnitsForBandResponse(unitsDataResponse.data);
        setSelectedBandUnits(sortedUnits);
        return Promise.resolve(sortedUnits);
    }

    async function initAppWithBookAndUnit(
        articleUuid?: string,
        unitFrom: string = "",
        unitTo: string = ""
    ): Promise<StartPageRedirectionTarget> {
        articleUuid = (articleUuid || localStorage.getItem("initUAID") || "").trim();
        unitFrom = (unitFrom || localStorage.getItem("initFromUUID") || "").trim();
        unitTo = (unitTo || localStorage.getItem("initToUUID") || "").trim();

        localStorage.removeItem("initUAID");
        localStorage.removeItem("initFromUUID");
        localStorage.removeItem("initToUUID");

        let redirectTo: StartPageRedirectionTarget = "BOOK";
        if (articleUuid) {
            setIsDataBeingLoaded(true);
            const loadInitArticleResponse = await loadInitArticleIdData(articleUuid, true);
            const bandId = loadInitArticleResponse.band?.ID;

            if (!bandId) {
                setIsDataBeingLoaded(false);
                return Promise.reject();
            }

            const unitsForBandResponse = await loadUnitsForBand(bandId);

            const unitsToLoad: Array<string> = [];
            if (unitFrom && unitTo) {
                const unitFromInfo = unitsForBandResponse.find((u) => u.uuid === unitFrom);
                const unitToInfo = unitsForBandResponse.find((u) => u.uuid === unitTo);

                if (unitFromInfo?.order_id && unitToInfo?.order_id) {
                    let minOrder = unitFromInfo.order_id;
                    let maxOrder = unitToInfo.order_id;

                    if (minOrder > maxOrder) {
                        [minOrder, maxOrder] = [maxOrder, minOrder];
                    }

                    unitsForBandResponse.forEach((unit) => {
                        if (unit.order_id && unit.order_id >= minOrder && unit.order_id <= maxOrder) {
                            unit?.id && unitsToLoad.push(unit.id);
                        }
                    });
                } else {
                    unitFromInfo?.id && unitsToLoad.push(unitFromInfo.id);
                    unitToInfo?.id && unitsToLoad.push(unitToInfo.id);
                }
            } else if (unitFrom) {
                const unitFromId = unitsForBandResponse.find((unit) => unit.uuid === unitFrom)?.id;
                if (unitFromId) {
                    unitsToLoad.push(unitFromId);
                }
            }

            if (unitsToLoad.length > 0) {
                await loadCardsForUnits(unitsToLoad);
                if (creatingMode === "RECURRING_TASK") {
                    unitsToLoad.forEach((u) => localStorage.setItem("open_" + u, "true"));
                } else {
                    setUnitsToPreloadIds(unitsToLoad);
                }
                redirectTo = "CARDS";
            }
            setIsDataBeingLoaded(false);
        } else {
            return Promise.reject();
        }
        return Promise.resolve(redirectTo);
    }

    function resetAppStatus() {
        setTestContent([]);
        setTestName("");
        setCurrentTestId("");
        setIsTestBeingEdited(false);
        setCurrentExerciseModel({});
        setShowScores(false);
        setSettings(getStandardOrDefaultConfiguration().settings);
        setSelectedBookData({});
        setSelectedUserSubject({});
        setShouldSubjectSelectionBeDisabled(false);
        setPreviouslyUsedCardsIds([]);
        setUserOwnSubjectUnits([]);
        setPreviouslyUsedCardsIds([]);
        setVocabularyDrawerContent({});
    }

    function removeContentFromVocabularyDrawer(contentType: "ALL" | "OWN" | "BOOK") {
        let idsToDelete: Array<string>;
        if (contentType === "ALL") {
            idsToDelete = Object.keys(vocabularyDrawerContent);
        } else {
            const drawerContentEntries = Object.entries(vocabularyDrawerContent);
            if (contentType === "OWN") {
                idsToDelete = drawerContentEntries
                    .filter(
                        ([key, value]) =>
                            value.wordContent && "isUserCard" in value.wordContent && value.wordContent.isUserCard
                    )
                    .map(([key, value]) => key);
            } else {
                idsToDelete = drawerContentEntries
                    .filter(([key, value]) => value.wordContent && "article_id" in value.wordContent)
                    .map(([key, value]) => key);
            }
        }
        removeCardsFromSelectedWords(idsToDelete);
    }

    async function reloadUserSubjects() {
        // const userExercises = [];
        setIsDataBeingLoaded(true);
        try {
            const userSubjectsResponse = await makePxpRequest("GET", "userSubjectContents");
            const processedResponse: Array<UserSubject> = [];
            const availableSubjects: AvailableUserOwnSubjectsInfo = {};
            if (
                userSubjectsResponse.data.replyContent.objects &&
                !!userSubjectsResponse.data.replyContent.objects.length
            ) {
                userSubjectsResponse.data.replyContent.objects.forEach((subject: any) => {
                    const subj = { ...subject[0], ...subject[1] };
                    processedResponse.push(subj);
                    availableSubjects[subj.id] = subj;
                });
            }
            setUserOwnSubjects(processedResponse);
            setIsDataBeingLoaded(false);
            setUserUsedOwnSubjectsInfo(availableSubjects);
            return Promise.resolve(processedResponse);
        } catch (e) {
            console.log(e);
        }
        setIsDataBeingLoaded(false);
    }

    async function loadUserUnitsForSubject(subjectUUID: string) {
        setIsDataBeingLoaded(true);
        const selectedSubject = userOwnSubjects.find((s) => s.id === subjectUUID);
        if (!selectedSubject) {
            setIsDataBeingLoaded(false);
            return;
        }
        setSelectedUserSubject(selectedSubject);

        try {
            const userUnitResponse = await makePxpRequest("GET", "userUnitsContents/" + subjectUUID);
            const processedResponse: Array<UserUnit> = [];
            const newUserUnitCards: any = {};
            if (userUnitResponse.data.httpCode === 200 && userUnitResponse.data.replyContent.objects.length) {
                userUnitResponse.data.replyContent.objects.forEach((unit: any) => {
                    const subjectIdToOwnerData = unit[0];
                    const subjectData = unit[1];
                    processedResponse.push({ ...subjectIdToOwnerData, ...subjectData, subjectId: subjectUUID });
                    newUserUnitCards[subjectIdToOwnerData.id] = [];
                });
            } else if (userUnitResponse.data.httpCode === 401) {
                if (userUnitResponse.data.exceptionClass === PXPErrorClasses.Unauthorized) {
                    setIsDataBeingLoaded(false);
                    history.push({
                        pathname: "/auth",
                        search: creatingMode === "RECURRING_TASK" ? "?redirectTo=rt-home" : "",
                    });
                } else {
                    throw new Error(userUnitResponse.data.exceptionMessage);
                }
            }
            setUserOwnSubjectUnits(processedResponse);
            setUserUnitCards(newUserUnitCards);
            setIsDataBeingLoaded(false);
            return Promise.resolve();
        } catch (e) {
            console.log(e);
        }
        setIsDataBeingLoaded(false);
    }

    async function loadUserUnitCards(unitUUID: string) {
        setIsDataBeingLoaded(true);

        const subjectUUID = selectedUserSubject.id;
        if (!subjectUUID) {
            setIsDataBeingLoaded(false);
            return;
        }

        try {
            const userUnitResponse = await makePxpRequest("GET", `userCardsContents/${subjectUUID}/${unitUUID}`);
            const processedResponse = processUserUnitCardsResponse(
                userUnitResponse.data.replyContent.objects,
                subjectUUID,
                unitUUID
            );
            const newCardsObj = {
                ...userUnitCards,
            };
            newCardsObj[unitUUID] = processedResponse;
            setUserUnitCards(newCardsObj);
            setIsDataBeingLoaded(false);
            return Promise.resolve();
        } catch (e) {
            console.log(e);
        }
        setIsDataBeingLoaded(false);
    }

    async function loadUserTestsSharingData() {
        setIsTestSharingInfoBeingLoaded(true);
        const studentTestsContent: StudentTestContentWrapper = await getTeacherSharedTests();
        setStudentTestContentWrapper(studentTestsContent);
        setIsTestSharingInfoBeingLoaded(false);
    }

    // Returning the length of the user's tests.
    async function loadUserTests(forceReload = false) {
        if (userId && (!dataLoaded || forceReload)) {
            setIsDataBeingLoaded(true);
            try {
                const userTests: Array<TeacherTestDetails> = await getUserTests();
                setUserTests(userTests);

                // Loading this after it's set to avoid inconsistencies.
                setIsTestSharingInfoBeingLoaded(true);
                loadUserTestsSharingData();
                reloadUserSubjects();

                const v1Tests = await getUserV1Tests();
                setVtgV1Tests(v1Tests);

                setDataLoaded(true);
                // Returning the amount of the user's tests.
                return Promise.resolve(userTests.length);
            } catch (e: any) {
                if (e.response.status === NetworkErrorCodes.FORBIDDEN) {
                    history.push("/unauthorized");
                    return Promise.reject();
                }
            } finally {
                setIsDataBeingLoaded(false);
            }
        }
    }

    function openWebAppInAddCardsMode() {
        if (userInfo.addCardsAutoLoginUrl && userInfo.addCardsAutoLoginUrl.length > 1) {
            window.open(userInfo.addCardsAutoLoginUrl, "_blank");
        }
    }

    async function decideGoToOwnSubjectsAction(forceNewContentCheck?: boolean): Promise<"CONTINUE" | "POPUP"> {
        if (forceNewContentCheck) {
            return reloadUserSubjects().then((res) => {
                if (res?.length) {
                    return Promise.resolve("CONTINUE");
                } else {
                    return Promise.resolve("POPUP");
                }
            });
        }
        if (userOwnSubjects.length) {
            return Promise.resolve("CONTINUE");
        } else {
            return Promise.resolve("POPUP");
        }
    }

    return {
        ...context,
        duplicateTest,
        deleteTest,
        shareTest,
        removeCardsFromSelectedWords,
        loadTestForEdition,
        loadVocabularyForBand,
        removeCardFromSelectedWords,
        loadCardsForUnit,
        addCardToSelectedWords,
        addCardsToSelectedWords,
        openWarningModal,
        prepareReducedTestDetails,
        prepareFullTestDetails,
        saveTest,
        createPreviewData,
        updateCurrentExerciseProperties,
        checkForAnonymousTests,
        deleteV1Test,
        downloadV1Test,
        canLogEvent,
        updateSharedTestProperties,
        loadUserTests,
        loadUserUnitsForSubject,
        loadUserUnitCards,
        loadUserOwnSubjectUUIDsInfoIntoState,
        resetAppStatus,
        reloadUserSubjects,
        loadInitArticleIdData,
        removeContentFromVocabularyDrawer,
        initAppWithBookAndUnit,
        openWebAppInAddCardsMode,
        decideGoToOwnSubjectsAction,
    };
}

function TestCreationContextProvider(props: any) {
    const [userTests, setUserTests] = useState<Array<TeacherTestDetails>>([]);
    const [studentTestContentWrapper, setStudentTestContentWrapper] = useState<StudentTestContentWrapper>({});
    const [dataLoaded, setDataLoaded] = useState(false);
    const [isTestSynced, setIsTestSynced] = useState<boolean | undefined>();
    const [isTitleSynced, setIsTitleSynced] = useState<boolean>(true);
    const [selectedBookData, setSelectedBookData] = useState<SelectedBookData>({});

    const [availableVerbtrainingTenses, setAvailableVerbtrainingTenses] =
        useState<Array<VerbtrainingTensesResponseItem>>(verbtrainingTenses);
    const [isVocabularyDrawerOpen, setIsVocabularyDrawerOpen] = useState(false);

    const [selectedBandUnits, setSelectedBandUnits] = useState<Array<BandUnit>>([]);

    const [availableCards, setAvailableCards] = useState<BandCards>({});
    const [vocabularyDrawerContent, setVocabularyDrawerContent] = useState<SidebarSelectedWordsList>({});

    const [maxAmountOfCardsSelectable, setMaxAmountOfCardsSelectable] = useState<number>(50);
    const [isWarningModalVisible, setIsWarningModalVisible] = useState(false);
    const [warningModalContent, setWarningModalContent] = useState<React.ReactNode>();

    const [testContent, setTestContent] = useState<Array<TestQuestionModel>>([]);
    const [testName, setTestName] = useState("");

    const [currentTestId, setCurrentTestId] = useState("");

    const [currentExerciseModel, setCurrentExerciseModel] = useState<ExerciseCreationModel>({});

    const [showScores, setShowScores] = useState(false);

    const [settings, setSettings] = useState<Settings>(getStandardOrDefaultConfiguration().settings);

    const [vtgV1Tests, setVtgV1Tests] = useState<Array<string>>([]);

    const [isTestBeingEdited, setIsTestBeingEdited] = useState(false);
    const [isTestSharingInfoBeingLoaded, setIsTestSharingInfoBeingLoaded] = useState(false);
    const [isFirstCreateHomeLoad, setIsFirstCreateHomeLoad] = useState(true);

    const [userOwnSubjects, setUserOwnSubjects] = useState<Array<UserSubject>>([]);
    const [userOwnSubjectUnits, setUserOwnSubjectUnits] = useState<Array<UserUnit>>([]);
    const [selectedUserSubject, setSelectedUserSubject] = useState<UserSubject>({});
    const [userUnitCards, setUserUnitCards] = useState<UserUnitCards>({});
    const [shouldSubjectSelectionBeDisabled, setShouldSubjectSelectionBeDisabled] = useState(false);

    // Exclusive vars for the vokabeltrainer exercises (recurring tasks)
    const [userUsedOwnSubjectsInfo, setUserUsedOwnSubjectsInfo] = useState<AvailableUserOwnSubjectsInfo>({});
    const [previouslyUsedCardsIds, setPreviouslyUsedCardsIds] = useState<Array<string>>([]);
    const [unitsToPreloadIds, setUnitsToPreloadIds] = useState<Array<any>>([]);

    const value = {
        userTests,
        setUserTests,
        isTestSynced,
        setIsTestSynced,
        isTitleSynced,
        setIsTitleSynced,
        studentTestContentWrapper,
        setStudentTestContentWrapper,
        dataLoaded,
        setDataLoaded,
        availableVerbtrainingTenses,
        setAvailableVerbtrainingTenses,
        selectedBookData,
        setSelectedBookData,
        isVocabularyDrawerOpen,
        setIsVocabularyDrawerOpen,
        vocabularyDrawerContent,
        setVocabularyDrawerContent,
        selectedBandUnits,
        setSelectedBandUnits,
        availableCards,
        setAvailableCards,
        maxAmountOfCardsSelectable,
        setMaxAmountOfCardsSelectable,
        isWarningModalVisible,
        setIsWarningModalVisible,
        warningModalContent,
        setWarningModalContent,
        testContent,
        setTestContent,
        testName,
        setTestName,
        currentTestId,
        setCurrentTestId,
        currentExerciseModel,
        setCurrentExerciseModel,
        showScores,
        setShowScores,
        settings,
        setSettings,
        vtgV1Tests,
        setVtgV1Tests,
        isTestBeingEdited,
        setIsTestBeingEdited,
        isTestSharingInfoBeingLoaded,
        setIsTestSharingInfoBeingLoaded,
        previouslyUsedCardsIds,
        setPreviouslyUsedCardsIds,
        isFirstCreateHomeLoad,
        setIsFirstCreateHomeLoad,
        userOwnSubjects,
        setUserOwnSubjects,
        userOwnSubjectUnits,
        setUserOwnSubjectUnits,
        selectedUserSubject,
        setSelectedUserSubject,
        userUnitCards,
        setUserUnitCards,
        userUsedOwnSubjectsInfo,
        setUserUsedOwnSubjectsInfo,
        shouldSubjectSelectionBeDisabled,
        setShouldSubjectSelectionBeDisabled,
        unitsToPreloadIds,
        setUnitsToPreloadIds,
    };

    return (
        <TestCreationContext.Provider
            value={value}
            {...props}
        />
    );
}

export { TestCreationContextProvider, useTestCreationContext };
