import { useEffect, useState } from "react";
import { useTestCreationContext } from "../context/TestCreationContext";

const useBookData = (articleId?: string) => {
    const { loadInitArticleIdData } = useTestCreationContext();

    const [bookData, setBookData] = useState({
        publisherName: "",
        bookName: "",
        bandShortName: "",
    });

    useEffect(() => {
        async function getAndSetBookData(articleId: string) {
            const bookResponseData = await loadInitArticleIdData(articleId, false, false);
            setBookData({
                bookName: bookResponseData?.publisherBook?.BookName ?? "",
                publisherName: bookResponseData?.publisherBook?.PublisherName ?? "",
                bandShortName: bookResponseData?.band?.ShortName ?? "",
            });
        }

        if (articleId) {
            getAndSetBookData(articleId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [articleId]);

    return { bookData };
};

export default useBookData;
