import PDFCollapsible from "../../pdfCollapsible/PDFCollapsible";
import { useT } from "@transifex/react";
import PDFGoodLuckSettingControl from "../../pdfSettingControl/specialisedControls/pdfGoodLuckSettingControl/PDFGoodLuckSettingControl";
import { ChangeSettingsParams } from "../../PDFConfiguration";
import { SettingsSection, SettingsSubSection, TeacherTestDetails } from "p6m-p6u";

interface PDFGoodLuckSettingsProps {
    teacherTestDetails: TeacherTestDetails;
    changeSettings: <T extends SettingsSection, K extends SettingsSubSection<T>>(
        changeSettingsParams: ChangeSettingsParams<T, K>
    ) => void;
}

const PDFGoodLuckSettings = (props: PDFGoodLuckSettingsProps) => {
    const t = useT();

    const t_goodLuck = t("Additional", { _tags: "PDFConfiguration" });

    return (
        <PDFCollapsible title={t_goodLuck}>
            <PDFGoodLuckSettingControl
                teacherTestDetails={props.teacherTestDetails}
                changeSettings={props.changeSettings}
            />
        </PDFCollapsible>
    );
};

export default PDFGoodLuckSettings;
