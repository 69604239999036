import { StyleSheet, View, Text } from "@react-pdf/renderer";
import { Settings } from "p6m-p6u";
import React from "react";
import { getStandardOrDefaultConfiguration } from "../../../helpers/PDFHelper";
import DesignConstants from "../../../constants/DesignConstants";
import PDFABGroup from "./pdfHeaderSubComponents/PDFABGroup";
import PDFClass from "./pdfHeaderSubComponents/PDFClass";
import PDFDate from "./pdfHeaderSubComponents/PDFDate";
import PDFLogoOrLuck from "./pdfHeaderSubComponents/PDFLogoOrLuck";
import PDFName from "./pdfHeaderSubComponents/PDFName";
import { SettingPosition } from "../../../enums/settings";

interface PDFHeaderProps {
    settings: Settings;
    pdfUnitNames?: string;
}

const PDFHeader: React.FC<PDFHeaderProps> = (props: PDFHeaderProps) => {
    const settings = props.settings ?? getStandardOrDefaultConfiguration().settings;
    const pdfHeaderSettings = settings.header;
    const pdfAdditionalSettings = settings.additional;

    const showClass = !!pdfHeaderSettings?.class?.show;
    const showName = !!pdfHeaderSettings?.name?.show;
    const showDate = !!pdfHeaderSettings?.date?.show;
    const showTopics =
        !props.settings?.titleAndTopics?.title?.show && !!props.settings?.titleAndTopics?.vocabularyTopics?.show;
    const showAB = !!pdfHeaderSettings?.abGroup?.show;

    const allVisibilityValues = [showClass, showName, showDate, showAB];
    const showLogoTop = allVisibilityValues.filter(Boolean).length <= 2; // if only <= 2 are active -> space for logo

    const showGoodLuck =
        !!pdfAdditionalSettings.goodLuck.show && pdfAdditionalSettings.goodLuck.position === SettingPosition.TOP;

    const showTopicsTop = showTopics && !showAB && !showClass && !showName && !showDate;
    const showABTop = showAB && (!showClass || !showName || !showDate); // if one of the default top line is off -> space for AB

    const isDateSpaceLeft = !showABTop && !showClass;
    const isDateDefined = !!pdfHeaderSettings?.date?.value;
    const isDateOnLeft = showLogoTop && (isDateSpaceLeft || isDateDefined);

    const renderGoodLuckOrLogo = () => {
        return (
            <PDFLogoOrLuck
                showGoodLuck={showGoodLuck}
                showTop={showLogoTop}
                goodLuckImageName={pdfAdditionalSettings.goodLuck.value}
            />
        );
    };

    const renderClass = () => {
        return <PDFClass className={pdfHeaderSettings?.class?.value} />;
    };

    const renderName = () => {
        return <PDFName />;
    };

    const renderDate = () => {
        return (
            <PDFDate
                date={pdfHeaderSettings?.date?.value}
                showDateLeft={showLogoTop}
                isDateFlex={!showName && !showLogoTop} // if name not present, date needs to do the flex itself to position right
            />
        );
    };

    const renderTopics = () => {
        return <Text style={styles.unitsTitle}>{props.pdfUnitNames}</Text>;
    };

    const renderABGroup = () => {
        return (
            <PDFABGroup
                showAtTop={showABTop}
                groupName={settings.header.abGroup.value}
            />
        );
    };

    return (
        <View style={styles.container}>
            <View style={styles.topContainer}>
                <View style={styles.topLineItems}>
                    {showDate && isDateOnLeft && renderDate()}
                    {showAB && showABTop && renderABGroup()}
                    {showTopics && showTopicsTop && props.pdfUnitNames && renderTopics()}
                    {showClass && renderClass()}
                    {showName && renderName()}
                    {showDate && !isDateOnLeft && renderDate()}
                </View>
                {showLogoTop && renderGoodLuckOrLogo()}
            </View>
            <View style={styles.bottomContainer}>
                <View style={styles.bottomLeftContainer}>
                    {showTopics && !showTopicsTop && props.pdfUnitNames && renderTopics()}
                    {showAB && !showABTop && renderABGroup()}
                </View>
                {!showLogoTop && renderGoodLuckOrLogo()}
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        width: "100%",
        display: "flex",
        marginTop: DesignConstants.PDF.GAP_XS,
        marginBottom: DesignConstants.PDF.GAP_S,
    },
    topContainer: {
        flexDirection: "row",
        paddingBottom: DesignConstants.PDF.GAP_XXS,
        borderBottomWidth: 1,
        borderBottomColor: DesignConstants.COLORS.DARK_GREY,
    },
    topLineItems: {
        flexDirection: "row",
        flex: 1,
        alignItems: "flex-end",
    },
    bottomContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-start",
        alignContent: "flex-start",
        paddingTop: DesignConstants.PDF.GAP_XS,
    },
    bottomLeftContainer: {
        flexDirection: "column",
        flex: 1,
    },
    unitsTitle: {
        fontSize: DesignConstants.PDF.FONTSIZE_REGULAR,
        color: DesignConstants.COLORS.DARK_GREY,
        whiteSpace: "nowrap",
    },
});

export default PDFHeader;
