import { useT } from "@transifex/react";
import { SettingsSection, SettingsSubSection, TeacherTestDetails } from "p6m-p6u";
import { getStandardOrDefaultConfiguration } from "../../../../../helpers/PDFHelper";
import PDFCollapsible from "../../pdfCollapsible/PDFCollapsible";
import { ChangeSettingsParams } from "../../PDFConfiguration";
import PDFSettingControl from "../../pdfSettingControl/PDFSettingControl";
import PDFABGroupSettingControl from "../../pdfSettingControl/specialisedControls/pdfABGroupSettingControl/PDFABGroupSettingControl";
import PDFDateSettingControl from "../../pdfSettingControl/specialisedControls/pdfDateSettingControl/PDFDateSettingControl";
import PDFTextInputSettingControl from "../../pdfSettingControl/specialisedControls/pdfTextInputSettingControl/PDFTextInputSettingControl";

type HeaderSettingWithValue = Extract<SettingsSubSection<"header">, "class" | "date">;

interface PDFHeaderSettingsProps {
    teacherTestDetails: TeacherTestDetails;
    changeSettings: <T extends SettingsSection, K extends SettingsSubSection<T>>(
        changeSettingsParams: ChangeSettingsParams<T, K>
    ) => void;
    createBVersionFromA: (overwrite?: boolean) => void;
    setShowBVersion: (showBVersion: boolean) => void;
}

const PDFHeaderSettings: React.FC<PDFHeaderSettingsProps> = (props) => {
    const t = useT();

    const standardOrDefaultSettings = getStandardOrDefaultConfiguration().settings;
    const settings = props.teacherTestDetails?.settings ?? standardOrDefaultSettings;
    const pdfHeaderSettings = settings.header;

    const t_header = t("Header", { _tags: "PDFConfiguration" });

    const t_class = t("Class", { _tags: "PDFConfiguration" });
    const t_prefillClass = t("Prefill class", { _tags: "PDFConfiguration" });

    const t_name = t("Name", { _tags: "PDFConfiguration" });
    const t_date = t("Date", { _tags: "PDFConfiguration" });
    const t_abGroup = t("A / B Group", { _tags: "PDFConfiguration" });

    const toggleHeaderSetting = (settingTitle: SettingsSubSection<"header">, isActive: boolean) => {
        props.changeSettings({
            sectionTitle: "header",
            settingTitle: settingTitle,
            setting: {
                key: "show",
                value: isActive,
            },
        });
    };

    const handleToggleABGroup = () => {
        if (!pdfHeaderSettings.abGroup.show) {
            props.setShowBVersion(true);
            props.createBVersionFromA();
        }
        toggleHeaderSetting("abGroup", !pdfHeaderSettings.abGroup.show);
    };

    const handleShuffleBVersion = () => {
        props.createBVersionFromA(true);
        props.setShowBVersion(true);
    };

    const changeHeaderValueSetting = (settingTitle: HeaderSettingWithValue, value: string | number) => {
        props.changeSettings({
            sectionTitle: "header",
            settingTitle: settingTitle,
            setting: {
                key: "value",
                value: value,
            },
        });
    };

    return (
        <PDFCollapsible title={t_header}>
            <PDFTextInputSettingControl
                settingTitle={t_class}
                placeHolder={t_prefillClass}
                isActive={pdfHeaderSettings.class?.show ?? standardOrDefaultSettings.header?.class?.show}
                currentText={pdfHeaderSettings.class.value}
                onToggle={() => toggleHeaderSetting("class", !pdfHeaderSettings.class.show)}
                handleSaveInput={(value: string) => {
                    changeHeaderValueSetting("class", value);
                }}
            />
            <PDFSettingControl
                variant={"toggle"}
                settingTitle={t_name}
                checked={pdfHeaderSettings.name?.show ?? standardOrDefaultSettings.header?.name?.show}
                onChange={() => {
                    toggleHeaderSetting("name", !pdfHeaderSettings.name?.show);
                }}
            />
            <PDFDateSettingControl
                settingTitle={t_date}
                isActive={pdfHeaderSettings.date?.show ?? standardOrDefaultSettings.header?.date?.show}
                currentDate={pdfHeaderSettings.date.value}
                onToggle={() => toggleHeaderSetting("date", !pdfHeaderSettings.date.show)}
                handleDateSelection={(value: number) => {
                    changeHeaderValueSetting("date", value);
                }}
            />
            <PDFABGroupSettingControl
                settingTitle={t_abGroup}
                isActive={pdfHeaderSettings.abGroup?.show ?? standardOrDefaultSettings.header.abGroup?.show}
                onToggle={handleToggleABGroup}
                onShuffleBVersion={handleShuffleBVersion}
            />
        </PDFCollapsible>
    );
};

export default PDFHeaderSettings;
