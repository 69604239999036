import { useT } from "@transifex/react";
import type { GoodLuckSettings, SettingsSection, SettingsSubSection, TeacherTestDetails } from "p6m-p6u";
import { useState } from "react";
import { GoodLuckImages } from "../../../../../../constants/GoodLuckConstants";
import { GoodLuckNames, SettingPosition } from "../../../../../../enums/settings";
import useClickOutside from "../../../../../../hooks/useClickOutside";
import { useGoodLuckTranslations } from "../../../../../../hooks/useGoodLuckTranslations";
import { ChangeSettingsParams } from "../../../PDFConfiguration";
import PDFSettingControl from "../../PDFSettingControl";
import PDFSettingDetails from "../../pdfSettingDetails/PDFSettingDetails";
import PDFSettingsTopBottomToggle from "../../../pdfSettingsTopBottomButton/PDFSettingsTopBottomToggle";
import { getGoodLuckImageByName } from "../../../../../../helpers/GoodLuckHelper";

import {
    DropdownButton,
    DropdownContainer,
    DropdownIcon,
    DropdownItem,
    DropdownList,
    PDFSettingsDropdownWrapper,
    PDFSettingsTopBottomWrapper,
} from "./StyledComponents";

interface PDFGoodLuckSettingControlProps {
    teacherTestDetails: TeacherTestDetails;
    changeSettings: <T extends SettingsSection, K extends SettingsSubSection<T>>(
        changeSettingsParams: ChangeSettingsParams<T, K>
    ) => void;
}

const PDFGoodLuckSettingControl = (props: PDFGoodLuckSettingControlProps) => {
    const { getTranslatedGoodLuckImgName } = useGoodLuckTranslations();

    const imageName = props.teacherTestDetails.settings?.additional.goodLuck.value;
    const currentImage = getGoodLuckImageByName(imageName);

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const dropdownRef = useClickOutside(() => setIsDropdownOpen(false));

    const t = useT();

    const t_goodLuck = t("Good Luck", { _tags: "PDFConfiguration" });
    const t_showImage = t("Show Image", { _tags: "PDFConfiguration" });
    const t_off = t("off", { _tags: "PDFConfiguration" });

    const goodLuckImageIsActive = !!props.teacherTestDetails?.settings?.additional?.goodLuck?.show;
    const imagePosition = props.teacherTestDetails?.settings?.additional?.goodLuck?.position;

    const getOppositePosition = (currentPosition: SettingPosition) => {
        return currentPosition === SettingPosition.TOP ? SettingPosition.BOTTOM : SettingPosition.TOP;
    };

    const changeGoodLuckSetting = <T extends keyof GoodLuckSettings>(key: T, value: GoodLuckSettings[T]) => {
        props.changeSettings({
            sectionTitle: "additional",
            settingTitle: "goodLuck",
            setting: { key, value },
        });
    };

    const togglePosition = () => {
        changeGoodLuckSetting("position", getOppositePosition(imagePosition));
    };

    const toggleVisibility = () => {
        const opposite = !props.teacherTestDetails.settings?.additional.goodLuck.show;
        changeGoodLuckSetting("show", opposite);
    };

    const handleImageChange = (goodLuckImageName: GoodLuckNames) => {
        setIsDropdownOpen(false);
        changeGoodLuckSetting("value", goodLuckImageName);
    };

    const renderImagesDropdown = () => {
        return (
            <DropdownContainer ref={dropdownRef}>
                <DropdownButton
                    onClick={() => goodLuckImageIsActive && setIsDropdownOpen(!isDropdownOpen)}
                    isDisabled={!goodLuckImageIsActive}
                >
                    {getTranslatedGoodLuckImgName(currentImage.name)}
                    <DropdownIcon name={`chevron-${isDropdownOpen ? "up" : "down"}`} />
                </DropdownButton>

                {isDropdownOpen && (
                    <DropdownList>
                        {GoodLuckImages.map((image) => (
                            <DropdownItem
                                key={image.id}
                                onClick={() => handleImageChange(image.name)}
                                isSelected={image.id === currentImage.id}
                            >
                                {getTranslatedGoodLuckImgName(image.name)}
                            </DropdownItem>
                        ))}
                    </DropdownList>
                )}
            </DropdownContainer>
        );
    };

    return (
        <PDFSettingControl
            variant={"expandable"}
            settingTitle={t_goodLuck}
            statusText={goodLuckImageIsActive ? getTranslatedGoodLuckImgName(currentImage.name) : t_off}
        >
            <PDFSettingsDropdownWrapper>
                <PDFSettingDetails
                    checked={goodLuckImageIsActive}
                    onChange={toggleVisibility}
                    statusText={t_showImage}
                >
                    {renderImagesDropdown()}
                </PDFSettingDetails>
                <PDFSettingsTopBottomWrapper>
                    <PDFSettingsTopBottomToggle
                        isTop={imagePosition === SettingPosition.TOP}
                        togglePosition={togglePosition}
                        disabled={!goodLuckImageIsActive}
                    />
                </PDFSettingsTopBottomWrapper>
            </PDFSettingsDropdownWrapper>
        </PDFSettingControl>
    );
};

export default PDFGoodLuckSettingControl;
