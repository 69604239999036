// LIBRARIES
import React, { useCallback, useEffect, useState } from "react";
import { useTestCreationContext } from "../../../context/TestCreationContext";
import { useBookContext } from "../../../context/BookContext";
import { useGeneralContext } from "../../../context/AppGeneralContext";
import { useHistory, useLocation } from "react-router-dom";
import { useT, UT } from "@transifex/react";
import TestListElement from "../../../complex/create/testListElement/TestListElement";
import { TeacherTestDetails } from "p6m-p6u";
import ArrowIcon from "../../../basic/arrowIcon/ArrowIcon";
import CreateInfoModal from "../../../basic/create/InfoModal/CreateInfoModal";
import { getCurrentDateTimeFormatted } from "../../../helpers/DateTime";
import { getStandardOrDefaultConfiguration } from "../../../helpers/PDFHelper";
import { logEvent } from "../../../logging/Logger";
import queryString from "query-string";
import SelectSearch from "react-select-search";
import { cp } from "../../../config";
import { tx } from "@transifex/native";
import { getLanguageName, getLanguageNameFromCode } from "../../../helpers/LanguageCountryName";
import {
    CreateHomeWrapper,
    CreateHomeHeader,
    HomeHeaderCTAS,
    CreateHomeTitle,
    CreateTestBtn,
    SortAndFilterWrapper,
    FiltersWrapper,
    SortingWrapper,
    AnimatedNormalSizeIcon,
    FilterSortingSpan,
    SelectSearchWrapper,
    NoTestsToShowText,
    NotATeacherWrapper,
    ContentWrapper,
    NotATeacherDesc,
    BackButtonWrapper,
    TestNameModalTextStrong,
    TestNameModalNameDesc,
    TestNameLabel,
    TestNameModalWrapper,
    TestNameRequiredWarning,
    StyledExerciseInputField,
    V1TestWrapper,
    V1TestsWrapper,
    V1TestName,
    V1TestsTitle,
    V1TestCTAs,
    V1TestCTAWrapper,
    SmallIcon,
    ActionName,
    BackLink,
} from "./StyledComponents";

export interface CreateHomeProps {}

export enum ShareStatusDropdownOptions {
    "ALL" = "ALL",
    "NOT_SHARED" = "NOT_SHARED",
    "SHARED" = "SHARED",
    "SHARED_WITH_ANSWERS" = "SHARED_WITH_ANSWERS",
}

export enum SortByDropdownOptions {
    "CREATE_DESC" = "CREATE_DESC",
    "CREATE_ASC" = "CREATE_ASC",
    "DUE_DATE_ASC" = "DUE_DATE_ASC",
    "DUE_DATE_DESC" = "DUE_DATE_DESC",
}

const CreateHome: React.FC<CreateHomeProps> = (props) => {
    const {
        userTests,
        studentTestContentWrapper,
        checkForAnonymousTests,
        canLogEvent,
        setTestName,
        vtgV1Tests,
        deleteV1Test,
        downloadV1Test,
        openWarningModal,
        isTestSharingInfoBeingLoaded,
        dataLoaded,
        setIsFirstCreateHomeLoad,
        isFirstCreateHomeLoad,
        loadUserTests,
        initAppWithBookAndUnit,
        setIsTestSynced,
        unitsToPreloadIds,
        setUnitsToPreloadIds,
        resetAppStatus,
        setShowScores,
    } = useTestCreationContext();

    const { checkForInitArticleId, setContentSelectionFiltersForTest } = useBookContext();

    const {
        userInfo,
        userId,
        setIsIframeMode,
        showRedirectOldVTGPopup,
        setShowRedirectOldVTGPopup,
        setCreatingMode,
        setIsDataBeingLoaded,
    } = useGeneralContext();

    const currentDate = getCurrentDateTimeFormatted();
    const { search } = useLocation();
    const urlParams = queryString.parse(search);

    const t = useT();
    const t_myTests = t("My Tests", { _tags: "CreateTest,CreateHome" });
    const t_CreateTest = t("Create new test", { _tags: "CreateTest,CreateHome" });
    const t_filterBy = t("Filter by:", { _tags: "CreateTest,CreateHome" });
    const t_sortBy = t("Sort by:", { _tags: "CreateTest,CreateHome" });
    const t_createAsc = t("Creation date (Ascending)", { _tags: "CreateTest,CreateHome" });
    const t_createDesc = t("Creation date (Descending)", { _tags: "CreateTest,CreateHome" });
    const t_dueDateAsc = t("Due date (Ascending)", { _tags: "CreateTest,CreateHome" });
    const t_dueDateDesc = t("Due date (Descending)", { _tags: "CreateTest,CreateHome" });
    const t_noLanguage = t("All", { _tags: "CreateTest,CreateHome" });
    const t_language = t("Language", { _tags: "CreateTest,CreateHome" });
    const t_shareStatus = t("Share Status", { _tags: "CreateTest,CreateHome" });
    const t_all = t("All", { _tags: "CreateTest,CreateHome" });
    const t_notShared = t("Not Shared", { _tags: "CreateTest,CreateHome" });
    const t_shared = t("Already Shared", { _tags: "CreateTest,CreateHome" });
    const t_sharedWithAnswers = t("Already Shared and answered", { _tags: "CreateTest,CreateHome" });
    const t_createNewTest = t("Create a new Test", { _tags: "CreateTest,CreateHome" });
    const t_noTestsFilters = t("No results matching the selected filter criteria", {
        _tags: "CreateTest,CreateHome",
    });
    const t_noTests = t("You haven't created any tests yet", { _tags: "CreateTest,CreateHome" });
    const t_back = t("Mein Konto", { _tags: "CreateTest,CreateHome" });
    const t_continue = t("Continue", { _tags: "CreateTest,CreateHome" });
    const t_giveADescription = t("Please give a description:", { _tags: "CreateTest,CreateHome" });
    const t_giveADescriptionPlaceholder = t("Test from {currentDate}", {
        currentDate,
        _tags: "CreateTest,CreateHome",
    });
    const t_nameRequiredWarning = t("Please give the test a name", { _tags: "CreateTest,CreateHome" });
    const t_v1TestsTitle = t("Version 1 (until 02.03.2021) - {v1Tests} Tests (only available as PDF)", {
        v1Tests: vtgV1Tests.length,
        _tags: "CreateTest,CreateHome",
    });
    const t_download = t("Download", { _tags: "CreateTest,CreateHome" });
    const t_delete = t("Delete", { _tags: "CreateTest,CreateHome" });
    const t_comingFromOldVTG = t("You have been redirected to the latest version", {
        _tags: "CreateTest,CreateHome",
    });

    const [displayList, setDisplayList] = useState<Array<TeacherTestDetails>>(userTests);
    const [sharedFilter, setSharedFilter] = useState<string>("ALL");
    const [sortBy, setSortBy] = useState<string>("CREATE_DESC");
    const [availableFilterLanguages, setAvailableFilterLanguages] = useState<Array<string>>([]);
    const [selectedLanguageFilter, setSelectedLanguageFilter] = useState<string>("");
    const [isTestNameModalOpen, setIsTestNameModalOpen] = useState<boolean>(false);
    const [newTestName, setNewTestName] = useState("");
    // const [useScoring, setUseScoring] = useState<"NO_SCORE" | "SCORE">("NO_SCORE");
    const [newTestIsBeingCreated, setNewTestIsBeingCreated] = useState(false);
    const [testNameInputTouched, setTestNameInputTouched] = useState(false);

    const [currentSelectedLanguage, setCurrentSelectedLanguage] = useState("");

    useEffect(() => {
        if (unitsToPreloadIds.length > 0) {
            setUnitsToPreloadIds([]);
        }
        if (localStorage.getItem("initAID")) {
            checkForInitArticleId(t_giveADescriptionPlaceholder);
        } else if (localStorage.getItem("initUAID")) {
            initAppWithBookAndUnit()
                .then((redirectTo) => {
                    setCreatingMode("TEST");
                    setTestName(t_giveADescriptionPlaceholder);
                    setContentSelectionFiltersForTest();
                    history.push(redirectTo === "BOOK" ? "/create/select-book" : "/create/select-vocabulary");
                })
                .catch(() => {
                    console.log("error initializing with book and unit.");
                });
        }
        setCurrentSelectedLanguage(tx.getCurrentLocale() || "");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (showRedirectOldVTGPopup) {
            openWarningModal(t_comingFromOldVTG);
            setShowRedirectOldVTGPopup(false);
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showRedirectOldVTGPopup]);

    useEffect(() => {
        if (!dataLoaded && userId && isFirstCreateHomeLoad) {
            setIsFirstCreateHomeLoad(false);
            checkForAnonymousTests().then(() => {
                loadUserTests().then((testsAmount) => {
                    if (testsAmount === 0) {
                        continueToCreateNewTest();
                    }
                });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataLoaded, userId, isFirstCreateHomeLoad, userTests]);

    useEffect(() => {
        if (urlParams.iframeMode) {
            setIsIframeMode(true);
        }
        if (urlParams.articleId) {
            let aId: string = urlParams.articleId as string;
            localStorage.setItem("initAID", aId);
            checkForInitArticleId(t_giveADescriptionPlaceholder);
        } else if (urlParams.articleUuid) {
            const unitFrom = (urlParams.unitUuid || urlParams.unitFromUuid || "") as string;
            const unitTo = (urlParams.unitToUuid || "") as string;
            initAppWithBookAndUnit(urlParams.articleUuid as string, unitFrom, unitTo)
                .then((redirectTo) => {
                    setCreatingMode("TEST");
                    setTestName(t_giveADescriptionPlaceholder);
                    setContentSelectionFiltersForTest();
                    history.push(redirectTo === "BOOK" ? "/create/select-book" : "/create/select-vocabulary");
                })
                .catch(() => {
                    history.push("/create/select-book");
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urlParams]);

    useEffect(() => {
        setDisplayList(userTests);
        let filterLanguages: Array<string> = [];
        userTests.forEach((t: TeacherTestDetails) => {
            let langName;
            if (t.language?.answerLanguageName) {
                langName = t.language.answerLanguageName;
                if (langName === "Deutsch") {
                    langName = t.language.questionLanguageName;
                }
                if (filterLanguages.indexOf(langName) < 0) {
                    filterLanguages.push(langName);
                }
            }
            if (t.ownSubjectInfo && t.ownSubjectInfo.primaryLang) {
                langName = t.ownSubjectInfo.primaryLang;
                if (langName === "de" && t.ownSubjectInfo.secondaryLang) {
                    langName = t.ownSubjectInfo.secondaryLang;
                }

                langName = getLanguageNameFromCode(langName);
                if (langName && filterLanguages.indexOf(langName) < 0) {
                    filterLanguages.push(langName);
                }
            }
        });
        setAvailableFilterLanguages(filterLanguages.sort());
    }, [userTests]);

    useEffect(() => {
        updateFilterList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sharedFilter, selectedLanguageFilter]);

    useEffect(() => {
        updateSorting();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortBy]);

    const updateFilterList = useCallback(
        () => {
            let listToSet = [...userTests];
            //update filter
            if (sharedFilter !== "ALL") {
                if (sharedFilter === "NOT_SHARED") {
                    listToSet = listToSet.filter((t) => !studentTestContentWrapper.hasOwnProperty(t.id));
                } else {
                    listToSet = listToSet.filter((t) =>
                        sharedFilter === "SHARED_WITH_ANSWERS"
                            ? studentTestContentWrapper.hasOwnProperty(t.id) &&
                              studentTestContentWrapper[t.id!].resultsCount &&
                              studentTestContentWrapper[t.id!].resultsCount! > 0
                            : studentTestContentWrapper.hasOwnProperty(t.id)
                    );
                }
            }

            if (selectedLanguageFilter !== "") {
                listToSet = listToSet.filter((t: TeacherTestDetails) => {
                    let answLangName = t.language?.answerLanguageName || "";
                    if (answLangName === "Deutsch") {
                        return t.language?.questionLanguageName === selectedLanguageFilter;
                    } else if (answLangName === "de") {
                        return getLanguageName(t.ownSubjectInfo?.primaryLang) === selectedLanguageFilter;
                    } else {
                        return (
                            t.language?.answerLanguageName === selectedLanguageFilter ||
                            getLanguageName(t.ownSubjectInfo?.primaryLang) === selectedLanguageFilter ||
                            getLanguageName(t.ownSubjectInfo?.secondaryLang) === selectedLanguageFilter
                        );
                    }
                });
            }

            // setDisplayList(listToSet);
            updateSorting(listToSet);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedLanguageFilter, sharedFilter]
    );

    const updateSorting = (listToSort?: Array<TeacherTestDetails>) => {
        //update sorting
        let newDisplayList = listToSort ? [...listToSort] : [...displayList];

        switch (sortBy) {
            case "CREATE_DESC":
                newDisplayList.sort((t1, t2) => (t2.updatedDate || 0) - (t1.updatedDate || 0));
                break;
            case "CREATE_ASC":
                newDisplayList.sort((t1, t2) => (t1.updatedDate || 0) - (t2.updatedDate || 0));
                break;
            case "DUE_DATE_DESC":
            case "DUE_DATE_ASC":
                newDisplayList.sort((t1, t2) => {
                    // check if theres shareTest data for the tests.
                    let firstIdExists = t1.id && studentTestContentWrapper.hasOwnProperty(t1.id);
                    let secIdExists = t2.id && studentTestContentWrapper.hasOwnProperty(t2.id);
                    if (firstIdExists && secIdExists) {
                        let t1DueDate = studentTestContentWrapper[t1.id!].dueDate || 0;
                        let t2DueDate = studentTestContentWrapper[t2.id!].dueDate || 0;

                        if (t1DueDate !== 0 && t2DueDate !== 0) {
                            return sortBy === "DUE_DATE_DESC" ? t2DueDate - t1DueDate : t1DueDate - t2DueDate;
                        } else if (t1DueDate === 0 && t2DueDate !== 0) {
                            return 1;
                        } else if (t1DueDate !== 0 && t2DueDate === 0) {
                            return -1;
                        } else {
                            return 0;
                        }
                    } else if (firstIdExists && !secIdExists) {
                        return -1;
                    } else if (!firstIdExists && secIdExists) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
                break;
        }

        setDisplayList(newDisplayList);
    };

    const history = useHistory();

    if (!userId) {
        history.push("/auth");
    }

    function redirectUser() {
        window.location.href = `${cp.cfg.REACT_APP_MEIN_KONTO}`;
    }

    function canShowLanguageDropdown() {
        return cp.cfg.ENVIRONMENT && cp.cfg.ENVIRONMENT !== "prod";
    }

    function createNewTestModalOpen() {
        resetAppStatus(); // resets current test data to start with clean data
        setIsTestSynced(false);
        setIsTestNameModalOpen(true);
    }

    function continueToCreateNewTest() {
        let testName = newTestName;
        if (testName.trim().length === 0) {
            if (!testNameInputTouched) {
                setNewTestName(t_giveADescriptionPlaceholder);
                testName = t_giveADescriptionPlaceholder;
            } else {
                return;
            }
        }

        setShowScores(!!getStandardOrDefaultConfiguration().showScores);
        setTestName(testName);
        setCreatingMode("TEST");
        setContentSelectionFiltersForTest();
        if (canLogEvent()) {
            logEvent("Vokabeltest v2 Started");
        }
        history.push("/create/choose-source");
        setNewTestIsBeingCreated(false);
    }

    function updateTestName(val: string) {
        setNewTestName(val);
        setTestNameInputTouched(true);
    }

    function getNameForShareStatusOption(option: string) {
        switch (option) {
            case ShareStatusDropdownOptions.ALL:
                return sharedFilter === ShareStatusDropdownOptions.ALL ? t_shareStatus : t_all;
            case ShareStatusDropdownOptions.SHARED:
                return t_shared;
            case ShareStatusDropdownOptions.SHARED_WITH_ANSWERS:
                return t_sharedWithAnswers;
            case ShareStatusDropdownOptions.NOT_SHARED:
                return t_notShared;
            default:
                return "no translation";
        }
    }

    function getNameForSortByOption(option: string) {
        switch (option) {
            case SortByDropdownOptions.CREATE_ASC:
                return t_createAsc;
            case SortByDropdownOptions.CREATE_DESC:
                return t_createDesc;
            case SortByDropdownOptions.DUE_DATE_ASC:
                return t_dueDateAsc;
            case SortByDropdownOptions.DUE_DATE_DESC:
                return t_dueDateDesc;
            default:
                return "no translation";
        }
    }

    function updateLanguage(newLanguage: string) {
        setIsDataBeingLoaded(true);
        tx.setCurrentLocale(newLanguage).finally(() => {
            setCurrentSelectedLanguage(tx.getCurrentLocale() || "");
            localStorage.setItem("lastTxLang", newLanguage);
            setIsDataBeingLoaded(false);
        });
    }

    const renderBackButton = () => {
        return (
            <BackButtonWrapper>
                <BackLink onClick={redirectUser}>
                    <ArrowIcon
                        onClick={() => {}}
                        direction={"LEFT"}
                        color={"inherit"}
                    />
                    {t_back}
                </BackLink>
                {canShowLanguageDropdown() && (
                    <div>
                        <select
                            value={currentSelectedLanguage}
                            onChange={(ev) => {
                                updateLanguage(ev.target.value as string);
                            }}
                        >
                            <option
                                key={"lang_de"}
                                value={"de"}
                            >
                                {"de"}
                            </option>
                            <option
                                key={"lang_en"}
                                value={"en_GB"}
                            >
                                {"en_GB"}
                            </option>
                        </select>
                    </div>
                )}
            </BackButtonWrapper>
        );
    };

    const renderHomeHeader = () => {
        return (
            <CreateHomeHeader>
                <CreateHomeTitle>{t_myTests}</CreateHomeTitle>
                <HomeHeaderCTAS>
                    {userInfo.visitorOrUserRole && userInfo.visitorOrUserRole === "teacher" && (
                        <CreateTestBtn
                            arrowDirection={"NONE"}
                            onClick={createNewTestModalOpen}
                        >
                            {t_CreateTest}
                        </CreateTestBtn>
                    )}
                </HomeHeaderCTAS>
            </CreateHomeHeader>
        );
    };

    const renderNotATeacher = () => {
        return (
            <NotATeacherWrapper>
                <NotATeacherDesc>
                    <UT
                        _str="The function to create Tests with your textbook was developed only for Teachers. You dont appear to be a teacher. <br/> But since you're here, phase6 have much more to offer, you can learn with our app. find out what can phase6 do better!."
                        _inline
                        _tags="CreateTest,CreateHome"
                    />
                </NotATeacherDesc>
            </NotATeacherWrapper>
        );
    };

    const renderFilter = () => {
        return (
            <FiltersWrapper className={isTestSharingInfoBeingLoaded ? "disabled" : ""}>
                <FilterSortingSpan>{t_filterBy}</FilterSortingSpan>
                <SelectSearchWrapper>
                    <SelectSearch
                        options={Object.keys(ShareStatusDropdownOptions).map((s) => {
                            return { name: getNameForShareStatusOption(s), value: s as string };
                        })}
                        emptyMessage={""}
                        value={sharedFilter}
                        onChange={(ev) => {
                            setSharedFilter(ev as any);
                        }}
                    />
                    <SelectSearch
                        options={[
                            { name: selectedLanguageFilter === "" ? t_language : t_noLanguage, value: "" },
                        ].concat(
                            availableFilterLanguages.map((lang) => {
                                return { name: lang, value: lang };
                            })
                        )}
                        emptyMessage={""}
                        value={selectedLanguageFilter}
                        onChange={(ev) => {
                            setSelectedLanguageFilter(ev as any);
                        }}
                    />
                </SelectSearchWrapper>
                {isTestSharingInfoBeingLoaded && <AnimatedNormalSizeIcon name={"sync"} />}
            </FiltersWrapper>
        );
    };

    const renderSorting = () => {
        return (
            <SortingWrapper className={isTestSharingInfoBeingLoaded ? "disabled" : ""}>
                <FilterSortingSpan>{t_sortBy}</FilterSortingSpan>
                <SelectSearchWrapper>
                    <SelectSearch
                        options={Object.keys(SortByDropdownOptions).map((s) => {
                            return { name: getNameForSortByOption(s), value: s as string };
                        })}
                        emptyMessage={""}
                        value={sortBy}
                        onChange={(ev) => {
                            setSortBy(ev as any);
                        }}
                    />
                </SelectSearchWrapper>
                {isTestSharingInfoBeingLoaded && <AnimatedNormalSizeIcon name={"sync"} />}
            </SortingWrapper>
        );
    };

    const renderV1Tests = () => {
        return (
            <V1TestsWrapper>
                <V1TestsTitle>{t_v1TestsTitle}</V1TestsTitle>
                {vtgV1Tests.map((t) => {
                    return (
                        <V1TestWrapper key={t}>
                            <V1TestName>{t}</V1TestName>
                            <V1TestCTAs>
                                <V1TestCTAWrapper
                                    onClick={() => {
                                        deleteV1Test(t);
                                    }}
                                >
                                    <SmallIcon name={"trash"} />
                                    <ActionName>{t_delete}</ActionName>
                                </V1TestCTAWrapper>
                                <V1TestCTAWrapper
                                    onClick={() => {
                                        downloadV1Test(t);
                                    }}
                                >
                                    <SmallIcon name={"download"} />
                                    <ActionName>{t_download}</ActionName>
                                </V1TestCTAWrapper>
                            </V1TestCTAs>
                        </V1TestWrapper>
                    );
                })}
            </V1TestsWrapper>
        );
    };

    const renderCreateInfo = () => {
        return (
            <CreateInfoModal
                isOpen={isTestNameModalOpen}
                onRequestClose={() => {
                    setIsTestNameModalOpen(false);
                }}
                continueAction={continueToCreateNewTest}
                continueText={t_continue}
                continueActionDisabled={newTestIsBeingCreated}
                compact
            >
                <TestNameModalWrapper>
                    <TestNameLabel>
                        <TestNameModalTextStrong>{t_createNewTest}</TestNameModalTextStrong>
                        <TestNameModalNameDesc>{t_giveADescription}</TestNameModalNameDesc>
                        <StyledExerciseInputField
                            value={newTestName}
                            placeholder={t_giveADescriptionPlaceholder}
                            onChange={(ev) => {
                                updateTestName(ev.target.value);
                            }}
                        />
                        {newTestName.trim().length === 0 && testNameInputTouched && (
                            <TestNameRequiredWarning>{t_nameRequiredWarning}</TestNameRequiredWarning>
                        )}
                    </TestNameLabel>
                </TestNameModalWrapper>
            </CreateInfoModal>
        );
    };

    return (
        <CreateHomeWrapper>
            {renderBackButton()}
            {renderHomeHeader()}

            {userInfo.visitorOrUserRole && userInfo.visitorOrUserRole !== "teacher" && renderNotATeacher()}
            {userInfo.visitorOrUserRole && userInfo.visitorOrUserRole === "teacher" && (
                <ContentWrapper>
                    <SortAndFilterWrapper>
                        {renderFilter()}
                        {renderSorting()}
                    </SortAndFilterWrapper>
                    {displayList.map((t, i) => {
                        return (
                            <TestListElement
                                teacherTestDetails={t}
                                key={t.id}
                                isTestSharingInfoBeingLoaded={isTestSharingInfoBeingLoaded}
                                index={i}
                            />
                        );
                    })}
                    {displayList.length === 0 && (
                        <NoTestsToShowText>
                            {sharedFilter !== "ALL" || selectedLanguageFilter !== "" ? t_noTestsFilters : t_noTests}
                        </NoTestsToShowText>
                    )}
                    {/* V1 tests are deprecated and can't be created anymore, but some teachers still have them and might need data from them.*/}
                    {/* (will hopefully be deleted in the future)*/}
                    {vtgV1Tests.length > 0 && renderV1Tests()}
                    {renderCreateInfo()}
                </ContentWrapper>
            )}
        </CreateHomeWrapper>
    );
};

export default CreateHome;
