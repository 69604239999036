import React, { ReactNode } from "react";
import { Page, Text, View, Document, StyleSheet, Image, Link } from "@react-pdf/renderer";
import { BandUnit, Settings } from "p6m-p6u";
import logo from "../../assets/img/p6-logo.png";
import DesignConstants from "../../constants/DesignConstants";
import PDFFooterSourceInfo from "./PDFFooterSourceInfo";
import PDFHeader from "./pdfHeader/PDFHeader";
import { getStandardOrDefaultConfiguration } from "../../helpers/PDFHelper";
import PDFReducedHeader from "./pdfHeader/PDFReducedHeader";
import { SettingPosition } from "../../enums/settings";
import PDFGradingOveralScoreAndSignatures from "./pdfGradingOverallScoreAndSignatures/PDFGradingOverallScoreAndSignatures";
import PDFTitle from "./pdfTitle/PDFTitle";

const _concatenateUnitNames = (units: BandUnit[]) => {
    const text = units.map((unit) => unit.name).join(", ");
    return text.length >= 70 ? text.slice(0, 70) + "..." : text;
};

interface PDFBasicWrapperProps {
    title?: string;
    bookArticleId?: string;
    bookData?: { publisherName: string; bookName: string; bandShortName: string };
    ownSubjectInfoName?: string;
    isSolution?: boolean;
    children?: ReactNode;
    settings?: Settings;
    overallScore?: number;
    showScores?: boolean;
    pdfUnits?: BandUnit[];
}

// Create Document Component
const PDFBasicWrapper: React.FC<PDFBasicWrapperProps> = (props: PDFBasicWrapperProps) => {
    const watermarkWidth = 20;
    const watermarkHeight = (watermarkWidth / 1000) * 402;

    const pdfUnitNames = props.pdfUnits ? _concatenateUnitNames(props.pdfUnits) : "";
    const standardOrDefaultSettings = getStandardOrDefaultConfiguration().settings;

    return (
        <Document title={props.title ?? ""}>
            <Page
                size="A4"
                style={styles.page}
            >
                <View
                    style={styles.paddingTop} // needed to add top & bottom padding to all pages
                    fixed
                />
                {props.isSolution && (
                    <View
                        style={styles.watermarkWrapper}
                        fixed
                    >
                        <Image
                            src={logo}
                            style={{
                                height: watermarkHeight + "cm",
                                width: watermarkWidth + "cm",
                            }}
                        />
                    </View>
                )}
                <PDFReducedHeader
                    title={props.title ?? ""}
                    showName={props.settings?.header.name.show}
                />
                <PDFHeader
                    settings={props.settings ?? standardOrDefaultSettings}
                    pdfUnitNames={pdfUnitNames}
                />
                <View style={styles.contentWrapper}>
                    <PDFGradingOveralScoreAndSignatures
                        position={SettingPosition.TOP}
                        overallScore={props.overallScore}
                        showScores={props.showScores}
                        settings={props.settings ?? standardOrDefaultSettings}
                    />
                    <PDFTitle
                        title={props.title ?? ""}
                        settings={props.settings ?? standardOrDefaultSettings}
                        pdfUnitNames={pdfUnitNames}
                    />
                    <View style={styles.exercisesWrapper}>{props.children ?? <View />}</View>
                </View>
                <PDFGradingOveralScoreAndSignatures
                    position={SettingPosition.BOTTOM}
                    overallScore={props.overallScore}
                    showScores={props.showScores}
                    settings={props.settings}
                />
                <Text
                    style={styles.pageNumber}
                    render={({ pageNumber, totalPages }) => totalPages > 1 && `${pageNumber} / ${totalPages}`}
                    fixed
                />
                <View
                    style={styles.footerContainer}
                    fixed
                >
                    {(props.bookArticleId || props.ownSubjectInfoName) && (
                        <PDFFooterSourceInfo
                            articleId={props.bookArticleId}
                            bookData={props.bookData}
                            ownSubjectInfoName={props.ownSubjectInfoName}
                            styles={{ footerText: styles.footerText }}
                        />
                    )}
                    <View style={styles.phase6Container}>
                        <Link
                            src={"https://www.phase6.de/"}
                            style={styles.footerText}
                        >
                            {"www.phase6.de"}
                        </Link>
                    </View>
                </View>
                <View
                    style={[
                        styles.paddingBottom,
                        {
                            height:
                                props.bookArticleId && props.ownSubjectInfoName
                                    ? DesignConstants.PDF.GAP_XXXL
                                    : DesignConstants.PDF.GAP_XXL,
                        },
                    ]} // needed to add top & bottom padding to all pages
                    fixed
                />
            </Page>
        </Document>
    );
};

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: "column",
        backgroundColor: "white",
        paddingHorizontal: DesignConstants.PDF.GAP_M,
        fontFamily: DesignConstants.PDF.PROXIMA_FONT,
        fontWeight: DesignConstants.PDF.REGULAR,
        color: DesignConstants.COLORS.DARKEST_GREY,
    },
    paddingTop: {
        height: "0.3cm",
    },
    paddingBottom: {
        bottom: 0,
    },
    pageNumber: {
        position: "absolute",
        fontSize: DesignConstants.PDF.FONTSIZE_REGULAR,
        textAlign: "center",
        color: DesignConstants.COLORS.MID_GREY,
        bottom: DesignConstants.PDF.GAP_L,
        left: 0,
        right: 0,
        margin: "auto",
    },
    watermarkWrapper: {
        position: "absolute",
        width: "100%",
        height: "100%",
        opacity: 0.2,
        alignItems: "center",
        justifyContent: "center",
        transform: "rotate(-45deg)",
        left: DesignConstants.PDF.GAP_M,
    },
    contentWrapper: {
        flexDirection: "column",
        justifyContent: "flex-start",
        flex: 1,
    },
    exercisesWrapper: {
        gap: DesignConstants.PDF.GAP_L,
    },
    footerContainer: {
        flexDirection: "row",
        position: "absolute",
        bottom: DesignConstants.PDF.GAP_S,
        width: "100%",
        left: DesignConstants.PDF.GAP_M,
        marginTop: DesignConstants.PDF.GAP_XXS,
    },
    phase6Container: {
        flex: 1,
        alignItems: "flex-end",
        justifyContent: "flex-end",
    },
    footerText: {
        fontSize: DesignConstants.PDF.FONTSIZE_REGULAR,
        color: DesignConstants.COLORS.MID_GREY,
    },
});

export default PDFBasicWrapper;
