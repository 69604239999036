import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { useT } from "@transifex/react";
import DesignConstants from "../../../../../constants/DesignConstants";

interface PDFAverageOverallGradeProps {
    showAverage: boolean;
    showOverallScore: boolean;
    showGradeField: boolean;
    overallScore?: number;
}

const PDFAverageOverallGrade = (props: PDFAverageOverallGradeProps) => {
    const t = useT();
    const t_overall = t("Overall:", { _tags: "PDFConfiguration" });
    const t_grade = t("Grade:", { _tags: "PDFConfiguration" });

    return (
        <View style={styles.topLineContainer}>
            {props.showAverage && (
                <View style={styles.averageWrapper}>
                    <Text style={styles.basicTextEmphasized}>{"Ø:"}</Text>
                </View>
            )}
            {props.showOverallScore && typeof props.overallScore === "number" && (
                <View
                    style={[
                        styles.overallScoreWrapper,
                        !props.showAverage || !props.showGradeField
                            ? {
                                  justifyContent: props.showGradeField ? "flex-start" : "flex-end",
                              }
                            : {},
                    ]}
                >
                    <Text style={styles.basicTextEmphasized}>{t_overall}</Text>
                    <Text style={styles.basicTextEmphasized}>{`/ ${props.overallScore}`}</Text>
                </View>
            )}
            {props.showGradeField && (
                <View style={styles.gradeWrapper}>
                    <Text style={styles.basicTextEmphasized}>{t_grade}</Text>
                </View>
            )}
        </View>
    );
};

const styles = StyleSheet.create({
    topLineContainer: {
        width: "100%",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    basicTextEmphasized: {
        fontSize: DesignConstants.PDF.FONTSIZE_REGULAR,
        fontWeight: DesignConstants.PDF.SEMIBOLD,
        color: DesignConstants.COLORS.DARK_GREY,
    },
    overallScoreWrapper: {
        flexDirection: "row",
        flex: 1,
        gap: DesignConstants.PDF.GAP_XL,
        justifyContent: "center",
    },
    averageWrapper: {
        flex: 1,
    },
    gradeWrapper: {
        flex: 1,
        marginRight: DesignConstants.PDF.GAP_XXL,
        alignItems: "flex-end",
    },
});

export default PDFAverageOverallGrade;
