import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { useT } from "@transifex/react";
import React from "react";
import DesignConstants from "../../../constants/DesignConstants";

interface PDFReducedHeaderProps {
    title: string;
    showName?: boolean;
}

const PDFReducedHeader: React.FC<PDFReducedHeaderProps> = (props: PDFReducedHeaderProps) => {
    const t = useT();
    const t_nameColon = t("Name:", { _tags: "PDFConfiguration" });

    return (
        <View
            render={({ pageNumber }) =>
                pageNumber > 1 && (
                    <View>
                        <View style={styles.repeatingHeaderTop}>
                            {props.title && <Text style={{ ...styles.headerText, flex: 2 }}>{props.title}</Text>}
                            {props.showName && pageNumber > 1 && (
                                <Text style={{ ...styles.headerText, flex: 1 }}>{t_nameColon}</Text>
                            )}
                        </View>
                        <View style={styles.repeatingHeaderBottom} />
                    </View>
                )
            }
            fixed
        />
    );
};

// please note: any spaces need to be in px to work inside the render param
const styles = StyleSheet.create({
    repeatingHeaderTop: {
        flexDirection: "row",
        paddingTop: "10px",
        paddingBottom: "5px",
        borderBottomWidth: 1,
        borderBottomColor: DesignConstants.COLORS.MID_GREY,
        justifyContent: "space-between",
        gap: DesignConstants.PDF.GAP_S,
    },
    repeatingHeaderBottom: {
        paddingBottom: "20px",
    },
    headerText: {
        fontSize: DesignConstants.PDF.FONTSIZE_REGULAR,
        fontWeight: DesignConstants.PDF.REGULAR,
        color: DesignConstants.COLORS.MID_GREY,
    },
});

export default PDFReducedHeader;
