import { StyleSheet, View } from "@react-pdf/renderer";
import { GoodLuckSettings } from "p6m-p6u";
import { ReactNode } from "react";
import DesignConstants from "../../../constants/DesignConstants";
import PDFGoodLuckImage from "./PDFGoodLuckImage";
import { SettingPosition } from "../../../enums/settings";

interface PDFGoodLuckImageWrapperProps {
    children: ReactNode;
    isLastElement: boolean;
    goodLuckSettings: GoodLuckSettings;
    showImageBelowExercise?: boolean;
    isSolution?: boolean;
}

export const PDFGoodLuckImageWrapper = (props: PDFGoodLuckImageWrapperProps) => {
    const showGoodLuckImage = props.goodLuckSettings.show;

    const position = props.goodLuckSettings.position;
    const settingImageBottomIsActive = showGoodLuckImage && position === SettingPosition.BOTTOM;
    const showImage = props.isLastElement && settingImageBottomIsActive && !props.isSolution;

    return (
        <View
            style={{
                ...styles.goodLuckContainer,
                flexDirection: props.showImageBelowExercise ? "column" : "row",
            }}
        >
            {showImage && !props.showImageBelowExercise ? (
                <View style={{ flex: 1 }}>{props.children}</View>
            ) : (
                props.children
            )}
            {showImage && (
                <View
                    style={{
                        ...styles.goodLuckImageWrapper,
                        justifyContent: props.showImageBelowExercise ? "flex-end" : "flex-start",
                    }}
                >
                    <PDFGoodLuckImage goodLuckImageName={props.goodLuckSettings.value} />
                </View>
            )}
        </View>
    );
};

const styles = StyleSheet.create({
    goodLuckContainer: {
        display: "flex",
        gap: DesignConstants.PDF.GAP_S,
    },
    goodLuckImageWrapper: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-end",
        border: `${DesignConstants.BORDERS.MEDIUM} ${DesignConstants.COLORS.WHITE}`,
    },
});
