import { useTestCreationContext } from "../context/TestCreationContext";
import { Scoring } from "../constants/ScoringConstants";
import { getExerciseScoringByMode } from "../helpers/ScoringHelper";
import { EditScoreDirection } from "../enums/directions";

export const useScoring = () => {
    const { currentExerciseModel, updateCurrentExerciseProperties, setIsTestSynced } = useTestCreationContext();

    const scoring = getExerciseScoringByMode(currentExerciseModel.questionMode ?? "");

    const _updateWordScoresEqually = (scorePerWord: number) => {
        if (!currentExerciseModel?.wordsInProgress) return;

        const updatedWords = currentExerciseModel.wordsInProgress.map((word) => ({
            ...word,
            wordScore: scorePerWord,
        }));

        updateCurrentExerciseProperties({
            wordsInProgress: updatedWords,
        });
        setIsTestSynced(false);
    };

    const adjustExerciseScore = ({
        direction,
        currentPointsPerWord,
    }: {
        direction: EditScoreDirection;
        currentPointsPerWord?: number;
    }) => {
        const adjustment = direction === EditScoreDirection.INCREMENT ? Scoring.POINTS_STEP : -Scoring.POINTS_STEP;
        const currentScore = scoring.storesScorePerWord
            ? currentPointsPerWord ?? 0
            : currentExerciseModel.exerciseScore ?? 0;

        const newScore = Math.max(Scoring.MIN_POINTS, currentScore + adjustment);

        if (scoring.storesScorePerWord) {
            _updateWordScoresEqually(newScore);
        } else {
            updateCurrentExerciseProperties({
                exerciseScore: newScore,
            });
            setIsTestSynced(false);
        }
    };

    return { adjustExerciseScore };
};
