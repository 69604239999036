import { useState } from "react";
import { useT } from "@transifex/react";
import { isEqual } from "lodash";
import { Settings, SettingsAndScores, TeacherTestDetails } from "p6m-p6u";
import { SettingsDefault, ConfigurationStorageKey } from "../../../../constants/SettingsConstants";
import { getParsedLocalStorageItem } from "../../../../helpers/LocalStorageHelper";
import StyledButton from "../../../../basic/styledButton/StyledButton";

import {
    SaveConfigurationButtonWrapper,
    SaveConfigurationButtonHoverWrapper,
    SaveConfigurationButtonToolTip,
} from "./StyledComponents";

interface PDFSettingsSaveConfigurationButtonProps {
    teacherTestDetails: TeacherTestDetails;
}

const _composeConfigurationObject = (teacherTestDetails: TeacherTestDetails): SettingsAndScores => {
    const settings = teacherTestDetails.settings;

    if (!settings) return { settings: SettingsDefault, showScores: teacherTestDetails.showScores ?? null };

    const cleanBAGroup = (abGroup: Settings["header"]["abGroup"]): Settings["header"]["abGroup"] => {
        const { bVersion, ...rest } = abGroup;
        return rest;
    };

    const cleanSettings: Settings = {
        ...settings,
        header: {
            ...settings.header,
            class: { ...settings.header.class, value: SettingsDefault.header.class.value },
            date: { ...settings.header.date, value: SettingsDefault.header.date.value },
            abGroup: cleanBAGroup(settings.header.abGroup),
        },
    };

    return {
        settings: cleanSettings,
        showScores: teacherTestDetails.showScores ?? null,
    };
};

const PDFSettingsSaveConfigurationButton = (props: PDFSettingsSaveConfigurationButtonProps) => {
    const t = useT();
    const t_saveConfiguration = t("Save as standard configuration", { _tags: "PDFConfiguration" });
    const t_saving = t("saving", { _tags: "PDFConfiguration" });
    const t_saved = t("Standard has been saved", { _tags: "PDFConfiguration" });

    const [isHovering, setIsHovering] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const localStorageConfiguration: SettingsAndScores | undefined = getParsedLocalStorageItem(ConfigurationStorageKey);
    const teacherTestDetailsConfiguration: SettingsAndScores = _composeConfigurationObject(props.teacherTestDetails);
    const hasUnchangedSettings = isEqual(
        teacherTestDetailsConfiguration,
        localStorageConfiguration ?? {
            settings: SettingsDefault,
            showScores: false,
        }
    );

    const handleSaveConfiguration = () => {
        setIsSaving(true);

        localStorage.setItem(ConfigurationStorageKey, JSON.stringify(teacherTestDetailsConfiguration));

        setTimeout(() => {
            setIsSaving(false);
        }, 1000);
    };

    return (
        <SaveConfigurationButtonWrapper>
            <SaveConfigurationButtonHoverWrapper
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
            >
                <StyledButton
                    onClick={handleSaveConfiguration}
                    buttonStyle="BLANK"
                    disabled={hasUnchangedSettings}
                >
                    {isSaving ? t_saving + " ..." : t_saveConfiguration}
                </StyledButton>
                {hasUnchangedSettings && isHovering && (
                    <SaveConfigurationButtonToolTip>{t_saved}</SaveConfigurationButtonToolTip>
                )}
            </SaveConfigurationButtonHoverWrapper>
        </SaveConfigurationButtonWrapper>
    );
};

export default PDFSettingsSaveConfigurationButton;
