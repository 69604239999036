import { View, Text, StyleSheet } from "@react-pdf/renderer";
import { Style } from "@react-pdf/types";
import DesignConstants from "../../constants/DesignConstants";

interface PDFFooterSourceInfoProps {
    articleId?: string;
    bookData?: { publisherName: string; bookName: string; bandShortName: string };
    ownSubjectInfoName?: string;
    styles: { footerText: Style };
}

const PDFFooterSourceInfo: React.FC<PDFFooterSourceInfoProps> = (props) => {
    const bookName = `${props.bookData?.publisherName ? props.bookData.publisherName + " | " : ""}${
        props.bookData?.bookName ? props.bookData.bookName + " | " : ""
    }${props.bookData?.bandShortName ?? ""}`;

    const checkForValidString = (text: string | undefined | null) => {
        // necessary, because invalid strings will cause warnings in pdf text in this case
        return text !== undefined && text !== null && text.trim() !== "";
    };

    const renderCopyright = () => {
        // the copyright symbol can currently only be rendered by using the standard font and a Ω
        // the spacing seems to be some kind of negative margin too that results in following letters appearing directly inside the symbol
        // that's why we add some spaces afterwards
        return <Text style={[props.styles.footerText, styles.fixedCopyright]}>{"Ω    "}</Text>;
    };

    return (
        <View style={styles.copyrightContainer}>
            <View style={styles.publisherWrapper}>
                {checkForValidString(props.bookData?.publisherName) && renderCopyright()}
                {checkForValidString(bookName) && <Text style={props.styles.footerText}>{bookName}</Text>}
            </View>
            {props.ownSubjectInfoName && props.ownSubjectInfoName !== " " && (
                <Text style={props.styles.footerText}>{props.ownSubjectInfoName}</Text>
            )}
        </View>
    );
};

// Create styles
const styles = StyleSheet.create({
    copyrightContainer: {
        flex: 4,
        lineHeight: 1,
    },
    publisherWrapper: {
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "flex-start",
    },
    fixedCopyright: {
        fontFamily: "Helvetica", // we need to use one of the default fonts to be able to render the copyright symbol
        fontSize: DesignConstants.PDF.FONTSIZE_REGULAR,
        paddingTop: DesignConstants.PDF.FONTSIZE_REGULAR / 14, //needed to balance out the font height & positioning difference to proxima
        lineHeight: 1,
    },
});

export default PDFFooterSourceInfo;
