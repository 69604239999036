import styled from "styled-components";
import DesignConstants from "../../constants/DesignConstants";
import PhaseSixIcon from "../phaseSixIcon/PhaseSixIcon";

export const AccordionTabWrapper = styled.div`
    margin: ${DesignConstants.SPACES.S} 0;
    background: white;
    border-radius: ${DesignConstants.BORDER_RADIUS.SMALL_CONTAINER};
`;

export const AccordionTabSummaryWrapper = styled.div<{ isAnonymous?: boolean; isActive?: boolean }>`
    position: sticky;
    top: ${(props) => (props.isAnonymous ? 0 : "116px")};
    background: white;
    z-index: 2;
    border-radius: ${DesignConstants.BORDER_RADIUS.SMALL_CONTAINER};
    cursor: ${({ isActive }) => !isActive && "pointer"};

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        top: 112px;
    }
`;

export const AccordionTabSummaryContentWrapper = styled.div<{ borderBottom?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${DesignConstants.SPACES.S} ${DesignConstants.SPACES.M};
    padding-bottom: ${(props) => (props.borderBottom ? DesignConstants.SPACES.XS : "")};
    border-bottom: ${(props) => (props.borderBottom ? DesignConstants.BORDERS.THIN_GREY : "")};
`;

export const AccordionTabSummary = styled.div<{ hidden?: boolean }>`
    display: ${(props) => (props.hidden ? "none" : "flex")};
    gap: ${DesignConstants.SPACES.XS};
`;

export const AccordionTabSummaryItem = styled.div`
    display: flex;
    align-items: center;
`;

export const AccordionTabText = styled.p<{ primaryColor?: boolean; isHighlighted?: boolean }>`
    font-weight: ${({ isHighlighted }) => isHighlighted && DesignConstants.FONT_WEIGHTS.SEMI_BOLD};
    font-size: ${DesignConstants.FONT_SIZES.L};
    color: ${(props) => (props.primaryColor ? DesignConstants.COLORS.P6_ORANGE : "")};
`;

export const StyledIcon = styled(PhaseSixIcon)<{
    $mediumWeight?: boolean;
    $primaryColor?: boolean;
}>`
    font-size: ${DesignConstants.FONT_SIZES.XXL};
    font-weight: ${(props) =>
        props.$mediumWeight ? DesignConstants.FONT_WEIGHTS.MEDIUM : DesignConstants.FONT_WEIGHTS.NORMAL};
    color: ${(props) => (props.$primaryColor ? DesignConstants.COLORS.P6_ORANGE : "")};
`;

export const AccordionTabDetailsWrapper = styled.div`
    padding: ${DesignConstants.SPACES.M};
`;

//TODO: StickyWrapper is a temporary solution until we improve the footer
// changing the position from fixed to sticky might help with the footer height change
export const StickyWrapper = styled.div`
    position: sticky;
    bottom: ${DesignConstants.SPACES.XXL};
    background: ${DesignConstants.COLORS.BACKGROUND_LIGHT};
    width: calc(100vw - 2rem);
    max-width: calc(${process.env.REACT_APP_UPPER_GRID_STEP_BORDER}px - 2rem);
    padding: ${DesignConstants.SPACES.XS} 0;
`;
