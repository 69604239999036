import { useT } from "@transifex/react";
import { SettingsSection, SettingsSubSection, TeacherTestDetails } from "p6m-p6u";
import { useMemo } from "react";
import { getStandardOrDefaultConfiguration } from "../../../../../../helpers/PDFHelper";
import { SettingPosition } from "../../../../../../enums/settings";
import { calculateTotalScore, ensureReliableOverallScore } from "../../../../../../helpers/ScoringHelper";
import { ChangeSettingsParams } from "../../../PDFConfiguration";
import PDFTopBottomSettingControl from "../pdfTopBottomSettingControl/PDFTopBottomSettingControl";
import PDFGradingScaleEditor from "./PDFGradingScaleEditor";

interface PDFGradingScaleControlProps {
    teacherTestDetails: TeacherTestDetails;
    toggleVisibility: (settingTitle: SettingsSubSection<"gradingAndSignatures">, isActive: boolean) => void;
    togglePosition: (
        settingTitle: SettingsSubSection<"gradingAndSignatures">,
        currentPosition: SettingPosition
    ) => void;
    changeSettings: <T extends SettingsSection, K extends SettingsSubSection<T>>(
        changeSettingsParams: ChangeSettingsParams<T, K>
    ) => void;
}

const PDFGradingScaleControl = (props: PDFGradingScaleControlProps) => {
    const cleanedUpTestDetails = useMemo(
        () => ensureReliableOverallScore(props.teacherTestDetails ?? {}),
        [props.teacherTestDetails]
    );

    const settings = cleanedUpTestDetails?.settings ?? getStandardOrDefaultConfiguration().settings;
    const pdfGradingScaleSettings = settings.gradingAndSignatures.gradingScale;
    const overallScore = calculateTotalScore(cleanedUpTestDetails?.content ?? []);

    const t = useT();
    const t_gradingScale = t("Grading scale", { _tags: "PDFConfiguration" });

    const isGradingScaleActive = !!pdfGradingScaleSettings?.show;
    const isGradingScaleDisabled = !isGradingScaleActive || overallScore < 2.5;

    return (
        <PDFTopBottomSettingControl
            settingTitle={t_gradingScale}
            topBottomSettingDetails={{
                isActive: isGradingScaleActive,
                position: pdfGradingScaleSettings.position,
                toggleOnOff: () => props.toggleVisibility("gradingScale", !isGradingScaleActive),
                togglePosition: () => props.togglePosition("gradingScale", pdfGradingScaleSettings.position),
            }}
            disabled={overallScore < 2.5}
        >
            <PDFGradingScaleEditor
                gradingScaleSettings={pdfGradingScaleSettings.scale}
                overallScore={overallScore}
                disabled={isGradingScaleDisabled}
                changeSettings={props.changeSettings}
            />
        </PDFTopBottomSettingControl>
    );
};

export default PDFGradingScaleControl;
