import { ExerciseCreationModel, TeacherTestDetails, TestQuestionModel, WordType } from "p6m-p6u";
import { getExerciseTypeByQuestionMode } from "./ExerciseTypesHelper";
import { ExerciseScoring, ExerciseScoringMap } from "../enums/exercises";
import { getStandardOrDefaultConfiguration } from "../helpers/PDFHelper";
import DesignConstants from "../constants/DesignConstants";

export const calculateTotalScore = (previewTestContent: TestQuestionModel[]) => {
    return previewTestContent.reduce((total, exercise) => total + getScoreForExercise(exercise), 0);
};

export const getExerciseScoringByMode = (qm: string) => {
    const exerciseType = getExerciseTypeByQuestionMode(qm);
    return exerciseType
        ? ExerciseScoringMap[exerciseType]
        : {
              storesScorePerWord: false,
              showScoreBubbles: false,
              canEditOverallScore: false,
          };
};

export const getScoreForExercise = (exercise: ExerciseCreationModel) => {
    const scoring = getExerciseScoringByMode(exercise.questionMode ?? "");

    const wordsToUse = exercise.wordsInProgress || exercise.selectedWords || [];
    return scoring.storesScorePerWord ? sumPerWordsExerciseScore(wordsToUse) : exercise.exerciseScore ?? 0;
};

const _setMissingExerciseScore = (exercise: ExerciseCreationModel) => {
    return {
        ...exercise,
        exerciseScore: 5,
    };
};

export const getScoreForWord = (scoring: ExerciseScoring, words: WordType[]) => {
    const existingScore = words.find((word) => word.wordScore)?.wordScore ?? 1;

    // for ConnectWord exercises (scoring.storesScorePerWord && scoring.canEditOverallScore), all words should have the same score (e.g., 2.5)
    // for other exercise types, default to 1 point per word
    return scoring.canEditOverallScore ? existingScore : 1;
};

const _setMissingWordScores = (exercise: ExerciseCreationModel, scoring: ExerciseScoring) => {
    const words = exercise.wordsInProgress || exercise.selectedWords || [];
    const wordIsMissingScore = words.some((word) => !word.wordScore);

    if (!wordIsMissingScore) return exercise;

    const existingPerWordScore = getScoreForWord(scoring, words);

    const updatedWords = words.map((word) => (word.wordScore ? word : { ...word, wordScore: existingPerWordScore }));

    return {
        ...exercise,
        wordsInProgress: exercise.wordsInProgress ? updatedWords : undefined,
        selectedWords: exercise.selectedWords ? updatedWords : undefined,
    };
};

export const setMissingScoresForExercise = (
    exercise: ExerciseCreationModel | TestQuestionModel,
    scoring: ExerciseScoring
): ExerciseCreationModel | TestQuestionModel => {
    if (scoring.storesScorePerWord) {
        return _setMissingWordScores(exercise, scoring);
    } else if (scoring.canEditOverallScore && !exercise.exerciseScore) {
        return _setMissingExerciseScore(exercise);
    }

    return exercise;
};

export const sumPerWordsExerciseScore = (words: WordType[]): number =>
    words.reduce((acc: number, word) => acc + (word.wordScore ?? 0), 0);

export const getExerciseScoreWidth = (num: number) => {
    // using font size, because the number width is directly influenced by it
    if (num < 10) {
        return DesignConstants.FONT_SIZES.XL;
    } else {
        return DesignConstants.FONT_SIZES.XXL;
    }
};

export const formatScoreToCommaSeparated = (score: number): string => score.toFixed(1).replace(".", ",");

// gets all words from an exercise, whether they're in progress or have been saved
const _getExerciseWords = (exercise: TestQuestionModel): WordType[] => {
    return exercise.wordsInProgress || exercise.selectedWords || [];
};

const _isExerciseMissingScore = (exercise: TestQuestionModel, scoring: ExerciseScoring): boolean => {
    if (scoring.storesScorePerWord) {
        return _getExerciseWords(exercise).some((word) => !word.wordScore);
    } else if (scoring.canEditOverallScore && !scoring.storesScorePerWord) {
        return !exercise.exerciseScore;
    }

    return false;
};

const _hasExercisesWithMissingScores = (exercises: TestQuestionModel[]): boolean => {
    return exercises.some((exercise) => {
        const exerciseScoring = getExerciseScoringByMode(exercise.questionMode ?? "");
        return _isExerciseMissingScore(exercise, exerciseScoring);
    });
};

export const handleMissingScores = (exercises: TestQuestionModel[]) => {
    if (!_hasExercisesWithMissingScores(exercises)) return;

    const exercisesWithScores = exercises.map((exercise: TestQuestionModel) => {
        const scoring = getExerciseScoringByMode(exercise.questionMode ?? "");
        return setMissingScoresForExercise(exercise, scoring);
    });

    return exercisesWithScores;
};

// used for pdf rendering and settings
export const ensureReliableOverallScore = (testDetails?: TeacherTestDetails) => {
    const settings = testDetails?.settings ?? getStandardOrDefaultConfiguration().settings;
    const isOverallScoreUsed =
        settings.gradingAndSignatures.overallScore.show || settings.gradingAndSignatures.gradingScale.show;

    const cleanedUpTestDetails = { ...testDetails };
    if (isOverallScoreUsed && !cleanedUpTestDetails?.showScores) {
        // if showScores, the correctness of the overall score is ensured
        // otherwise the overall score might not be up to date, and showing elements that work with it will cause issues

        // fix scores temporarily for displaying
        const newExercisesWithScores = handleMissingScores(cleanedUpTestDetails?.content ?? []);
        if (newExercisesWithScores) {
            cleanedUpTestDetails.content = newExercisesWithScores;
        }
    }

    return cleanedUpTestDetails;
};
