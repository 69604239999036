import styled from "styled-components";
import DesignConstants from "../../../../constants/DesignConstants";

export const SaveConfigurationButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: relative;
`;

export const SaveConfigurationButtonHoverWrapper = styled.div``;

export const SaveConfigurationButtonToolTip = styled.div`
    position: absolute;
    top: -${DesignConstants.SPACES.XL};
    right: ${DesignConstants.SPACES.S};
    padding: ${DesignConstants.SPACES.XS} ${DesignConstants.SPACES.S};
    background: ${DesignConstants.COLORS.WHITE};
    border-radius: ${DesignConstants.BORDER_RADIUS.SMALL_CONTAINER};
    border: ${DesignConstants.BORDERS.THIN_GREY};
    font-size: ${DesignConstants.FONT_SIZES.M};
    font-family: inherit;
`;
