import { GoodLuckImage } from "p6m-p6u";
import { GoodLuckNames } from "../enums/settings";
import { GoodLuckImages } from "../constants/GoodLuckConstants";
import { getStandardOrDefaultConfiguration } from "./PDFHelper";

export const getGoodLuckImageByName = (imageName: GoodLuckNames): GoodLuckImage => {
    const selectedImage = GoodLuckImages.find((img) => img.name === imageName);

    if (selectedImage) return selectedImage;

    const defaultImg = GoodLuckImages.find(
        (img) => img.name === getStandardOrDefaultConfiguration().settings.additional.goodLuck.value
    );
    return defaultImg || GoodLuckImages[0];
};
