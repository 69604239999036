import { useState } from "react";
import { useT } from "@transifex/react";
import InputWithActions from "../../../../../../basic/inputWithActions/InputWithActions";
import DesignConstants from "../../../../../../constants/DesignConstants";
import PDFSettingControl from "../../PDFSettingControl";
import PDFSettingDetails from "../../pdfSettingDetails/PDFSettingDetails";

import { InputWrapper } from "./StyledComponents";

interface PDFTextInputSettingControlProps {
    settingTitle: string;
    placeHolder: string;
    isActive: boolean;
    currentText?: string;
    onToggle: () => void;
    handleSaveInput: (wip: string) => void;
}

const PDFTextInputSettingControl = (props: PDFTextInputSettingControlProps) => {
    const [isEditing, setIsEditing] = useState(false);
    const [currentText, setCurrentText] = useState(props.currentText ?? "");

    const t = useT();

    const t_on = t("on", { _tags: "PDFConfiguration" });
    const t_off = t("off", { _tags: "PDFConfiguration" });

    const renderInputField = () => {
        return (
            <InputWrapper isEditing={isEditing}>
                <InputWithActions
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    placeholder={props.placeHolder}
                    currentText={currentText}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const input = e.target.value;
                        setCurrentText(input);
                    }}
                    onSave={() => {
                        if (props.currentText !== currentText) props.handleSaveInput(currentText);
                        setIsEditing(false);
                    }}
                    onDiscard={() => {
                        setCurrentText(props.currentText ?? "");
                        setIsEditing(false);
                    }}
                    onDelete={() => {
                        setCurrentText("");
                        props.handleSaveInput("");
                        setIsEditing(false);
                    }}
                    iconsize={DesignConstants.FONT_SIZES.XL}
                    fontSize={DesignConstants.FONT_SIZES.M}
                    underlined
                    disabled={!props.isActive}
                />
            </InputWrapper>
        );
    };

    return (
        <PDFSettingControl
            variant={"expandable"}
            settingTitle={props.settingTitle}
            statusText={props.isActive ? props.currentText || t_on : t_off}
        >
            <PDFSettingDetails
                checked={props.isActive}
                onChange={props.onToggle}
            >
                {renderInputField()}
            </PDFSettingDetails>
        </PDFSettingControl>
    );
};

export default PDFTextInputSettingControl;
