import { StyleSheet, View } from "@react-pdf/renderer";
import { Settings } from "p6m-p6u";
import { getStandardOrDefaultConfiguration } from "../../../helpers/PDFHelper";
import DesignConstants from "../../../constants/DesignConstants";
import { SettingPosition } from "../../../enums/settings";
import PDFAverageOverallGrade from "./pdfGradingOverallScoreSignaturesSubComponents/pdfGradingScale/PDFAverageOverallGrade";
import PDFGradingScale from "./pdfGradingOverallScoreSignaturesSubComponents/pdfGradingScale/PDFGradingScale";
import PDFSignatures from "./pdfGradingOverallScoreSignaturesSubComponents/PDFSignatures";

type GradingAndSignaturesKey = keyof Settings["gradingAndSignatures"];

interface PDFGradingOveralScoreAndSignaturesProps {
    position: SettingPosition;
    overallScore?: number;
    settings?: Settings;
    showScores?: boolean;
}

const PDFGradingOveralScoreAndSignatures = (props: PDFGradingOveralScoreAndSignaturesProps) => {
    const settings = props.settings ?? getStandardOrDefaultConfiguration().settings;
    const pdfGradingSignaturesSettings = settings.gradingAndSignatures;

    function shouldShowElement(elementName: GradingAndSignaturesKey) {
        const fullElement = pdfGradingSignaturesSettings?.[elementName];
        return !!fullElement.show && fullElement?.position === props.position;
    }

    const showAverage = shouldShowElement("average");
    const showOverallScore = shouldShowElement("overallScore");
    const showGradeField = shouldShowElement("grade");
    const showGradingScale = shouldShowElement("gradingScale");
    const showParentSignature = shouldShowElement("parentSignature");
    const showTeacherSignature = shouldShowElement("teacherSignature");

    const isNothingShowing =
        !showAverage &&
        !showOverallScore &&
        !showGradeField &&
        !showGradingScale &&
        !showParentSignature &&
        !showTeacherSignature;

    if (isNothingShowing) {
        return null;
    }

    return (
        <View style={styles.outerContainer}>
            {(showAverage || showOverallScore || showGradeField) && (
                <PDFAverageOverallGrade
                    showAverage={showAverage}
                    showOverallScore={showOverallScore}
                    showGradeField={showGradeField}
                    overallScore={props.overallScore}
                />
            )}
            {showGradingScale && (props.overallScore ?? 100) >= 2.5 && (
                <PDFGradingScale
                    gradingScaleSettings={pdfGradingSignaturesSettings?.gradingScale?.scale}
                    overallScore={props.overallScore}
                />
            )}
            {(showParentSignature || showTeacherSignature) && (
                <PDFSignatures
                    showParentSignature={showParentSignature}
                    showTeacherSignature={showTeacherSignature}
                />
            )}
        </View>
    );
};

const styles = StyleSheet.create({
    outerContainer: {
        gap: DesignConstants.PDF.GAP_XS,
        width: "100%",
        marginTop: DesignConstants.PDF.GAP_S,
        marginBottom: DesignConstants.PDF.GAP_M,
    },
});

export default PDFGradingOveralScoreAndSignatures;
