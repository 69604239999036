import {
    NearestAdaptableRange,
    MinMaxGradeRange,
    MinMaxGradingScale,
    PercentageGradeRange,
    PercentageGradingScale,
} from "p6m-p6u";
import { Scoring } from "../constants/ScoringConstants";
import { GradeRangeLimitType } from "../enums/settings";
import { EditScoreDirection } from "../enums/directions";
import { getStandardOrDefaultConfiguration } from "./PDFHelper";

// for debugging the grading scale only
// export const logGradingScale = (acc?: MinMaxGradingScale) => {
//     if (!acc) {
//         console.log(" - - no grading scale - -");
//         return;
//     }

//     let line1 = "";
//     let line2 = "";

//     acc.forEach((element) => {
//         line1 = line1 + `|     ${element.grade}     `;
//     });
//     acc.forEach((element) => {
//         line2 = line2 + `| ${element.maxScore.toFixed(1)} - ${element.minScore.toFixed(1)} `;
//     });

//     console.log(line1);
//     console.log(line2);
// };

type EditGradeRangeParams = {
    gradingScale: MinMaxGradingScale;
    shiftingTarget: number;
    index: number;
    minOrMax: GradeRangeLimitType;
};

type UpdateGradeRangeParams = {
    gradingScale: MinMaxGradingScale;
    nearestAdaptableRanges: NearestAdaptableRange[];
    index: number;
    minOrMax: GradeRangeLimitType;
    direction: EditScoreDirection;
};

export const createPercentageFromMinMax = (gradingScale: MinMaxGradingScale) => {
    const overallScore = gradingScale[0].maxScore;
    const percentageGradingScale = gradingScale.reduce(
        (percentageScoreRanges: PercentageGradeRange[], scaleElement: MinMaxGradeRange) => {
            const minScorePercentage = (scaleElement.minScore / overallScore) * 100;

            percentageScoreRanges.push({
                grade: scaleElement.grade,
                minScorePercentage,
            });

            return percentageScoreRanges;
        },
        []
    );

    return percentageGradingScale;
};

const _createMinMaxFromPercentage = (overallScore: number, gradingScale: PercentageGradingScale) => {
    const doubleOverallScore = (overallScore ?? 100) * 2; // to be able to round to .5

    const minMaxGradingScale = gradingScale.reduce(
        (minMaxScores: MinMaxGradeRange[], scaleElement: PercentageGradeRange, index: number) => {
            const minPercentage = scaleElement.minScorePercentage / 100;

            const minScore = Math.round(minPercentage * doubleOverallScore) / 2; // "rounding" to .5

            const maxScore =
                index === 0
                    ? overallScore // first element's max is overall score of test
                    : Math.max(minMaxScores[index - 1].minScore - Scoring.POINTS_STEP, 0); // max is previous min - 0.5

            minMaxScores.push({
                grade: scaleElement.grade,
                minScore,
                maxScore,
            });

            return minMaxScores;
        },
        []
    );

    return minMaxGradingScale;
};

const _increaseGradeRangeValue = (editGradeRangeParams: EditGradeRangeParams) => {
    const { gradingScale, shiftingTarget, index, minOrMax } = editGradeRangeParams;

    if (!gradingScale || gradingScale.length === 0) return gradingScale;

    const gradeRange = gradingScale[index];
    const isEditMin = minOrMax === GradeRangeLimitType.MIN;
    const isRangeReduction = isEditMin && gradeRange.minScore < gradeRange.maxScore;

    let newScale = [...gradingScale];
    if (isRangeReduction) {
        // update current range
        newScale[index].minScore += Scoring.POINTS_STEP;

        if (index + 1 < gradingScale.length) {
            // follow with next grade range
            newScale[index + 1].maxScore += Scoring.POINTS_STEP;
        }
    } else if (shiftingTarget >= 0 && shiftingTarget <= index) {
        // update current range
        if (isEditMin) newScale[index].minScore += Scoring.POINTS_STEP;
        newScale[index].maxScore += Scoring.POINTS_STEP;

        // shifting necessary
        newScale = _redistributeScoresLeft(gradingScale, index - 1, shiftingTarget);
    }
    return newScale;
};

const _decreaseGradeRangeValue = (editGradeRangeParams: EditGradeRangeParams) => {
    const { gradingScale, shiftingTarget, index, minOrMax } = editGradeRangeParams;

    if (!gradingScale || gradingScale.length === 0) return gradingScale;

    const gradeRange = gradingScale[index];
    const isEditMax = minOrMax === GradeRangeLimitType.MAX;
    const isRangeReduction = isEditMax && gradeRange.minScore < gradeRange.maxScore;

    let newScale = [...gradingScale];
    if (isRangeReduction) {
        // update current range
        newScale[index].maxScore -= Scoring.POINTS_STEP;

        if (index - 1 >= 0) {
            // follow with previous range
            newScale[index - 1].minScore -= Scoring.POINTS_STEP;
        }
    } else if (shiftingTarget >= 0 && shiftingTarget >= index) {
        //update current range
        if (isEditMax) newScale[index].maxScore -= Scoring.POINTS_STEP;
        newScale[index].minScore = newScale[index].minScore - Scoring.POINTS_STEP;

        //shifting necessary
        newScale = _redistributeScoresRight(gradingScale, index + 1, shiftingTarget);
    }
    return newScale;
};

export const updateGradeRange = (updateGradeRangeParams: UpdateGradeRangeParams) => {
    const { gradingScale, index, minOrMax, direction, nearestAdaptableRanges } = updateGradeRangeParams;

    let newScale: MinMaxGradingScale = [...gradingScale];

    if (direction === EditScoreDirection.INCREMENT) {
        newScale = _increaseGradeRangeValue({
            gradingScale,
            shiftingTarget: nearestAdaptableRanges[index].left,
            index,
            minOrMax,
        });
    } else {
        newScale = _decreaseGradeRangeValue({
            gradingScale,
            shiftingTarget: nearestAdaptableRanges[index].right,
            index,
            minOrMax,
        });
    }

    return newScale;
};

export const findAllAdaptableRangeTargets = (gradingScale: MinMaxGradingScale): NearestAdaptableRange[] => {
    let adaptableRanges = gradingScale.reduce((acc: NearestAdaptableRange[], _gradeRange: MinMaxGradeRange) => {
        acc.push({ left: -1, right: -1 });
        return acc;
    }, []);

    gradingScale.forEach((gradeRange: MinMaxGradeRange, index: number) => {
        const hasAdjustableRange = gradeRange.maxScore > gradeRange.minScore;
        if (hasAdjustableRange) {
            // add to all on left
            if (index - 1 >= 0) {
                for (let i = index - 1; i >= 0; i--) {
                    if (adaptableRanges[i].right === -1) adaptableRanges[i].right = index; // always write, because this is the closest
                }
            }

            // add to all on right
            if (index + 1 < gradingScale.length) {
                for (let j = index + 1; j < gradingScale.length; j++) {
                    adaptableRanges[j].left = index; // only set if not yet, because older is closer
                }
            }
        }
    });

    return adaptableRanges;
};

const _findAdaptableRangeTarget = (gradingScale: MinMaxGradingScale, i: number, direction: "RIGHT" | "LEFT") => {
    const startingPointRight = i + 1 < gradingScale.length ? i + 1 : i;
    const startingPointLeft = i - 1 >= 0 ? i - 1 : i;

    const startingPoint = direction === "RIGHT" ? startingPointRight : startingPointLeft;

    let index = -1;
    for (
        let j = startingPoint;
        direction === "RIGHT" ? j <= gradingScale.length - 1 : j >= 0;
        direction === "RIGHT" ? j++ : j--
    ) {
        const gradeRange = gradingScale[j];

        const hasAdjustableRange = gradeRange.maxScore > gradeRange.minScore;
        if (hasAdjustableRange) {
            index = j;
            break;
        }
    }

    return index;
};

const _redistributeScoresRight = (gradingScale: MinMaxGradingScale, startIndex: number, shiftingTarget: number) => {
    // move current grade and all grades to the right down by 0.5
    // and make target take up less space by not moving its min
    const cleanedUpGradingScale = [...gradingScale];
    for (let j = startIndex; j <= shiftingTarget; j++) {
        cleanedUpGradingScale[j].maxScore -= Scoring.POINTS_STEP; // go down

        if (j !== shiftingTarget) {
            // follow with min unless it's the target
            cleanedUpGradingScale[j].minScore -= Scoring.POINTS_STEP;
        }
    }
    return cleanedUpGradingScale;
};

const _redistributeScoresLeft = (gradingScale: MinMaxGradingScale, startIndex: number, shiftingTarget: number) => {
    const cleanedUpGradingScale = [...gradingScale];

    // move all grades left to us up by 0.5
    // and make target take up less space by not moving its max
    for (let j = startIndex; j >= shiftingTarget; j--) {
        cleanedUpGradingScale[j].minScore += Scoring.POINTS_STEP; // go up

        if (j > shiftingTarget) {
            // follow with max unless it's the target
            cleanedUpGradingScale[j].maxScore += Scoring.POINTS_STEP;
        }
    }

    return cleanedUpGradingScale;
};

/**
 * Cleans up the grading scale to avoid duplicate or overlapping score ranges:
 * - The last element's minScore is always 0
 * - No other minScore is 0
 * - Ensures minScore <= maxScore for all elements
 * - Fixes overlapping ranges by redistributing scores accordingly
 *
 * A visual representation of the redistribution logic can be found in our how to <3
 * https://horizon-alpha.atlassian.net/wiki/spaces/LM/pages/2969993238/AEA+Lehrkr+ftetool#_cleanUpGradingScale
 */
const _cleanUpGradingScale = (gradingScale: MinMaxGradingScale) => {
    let cleanedUpGradingScale = [...gradingScale];

    //____________BASE CLEAN UP ___________________________________________
    // - last element's min should always be 0
    if (cleanedUpGradingScale[cleanedUpGradingScale.length - 1].minScore !== 0) {
        cleanedUpGradingScale[cleanedUpGradingScale.length - 1].minScore = 0;
    }

    //____________FIRST CLEAN UP CYCLE ____________________________________
    cleanedUpGradingScale.forEach((gradeRange, i) => {
        if (gradeRange.minScore === 0 && i !== cleanedUpGradingScale.length - 1) {
            // - no other minScore should be 0 than the last
            gradeRange.minScore = Scoring.POINTS_STEP;
        }

        if (gradeRange.minScore > gradeRange.maxScore) {
            // - minScore <= maxScore for all elemenets
            gradeRange.maxScore = gradeRange.minScore;
        }
    });

    //____________SECOND CLEAN UP CYCLE ____________________________________
    // - Redistributes scores if necessary to avoid overlaps and duplicates
    const rangesToCleanUp = cleanedUpGradingScale.some((gradeRange: MinMaxGradeRange, i: number) => {
        return i > 0 && cleanedUpGradingScale[i - 1].minScore <= gradeRange.maxScore; // current max score overlaps with previous min score
    });

    if (!rangesToCleanUp) return cleanedUpGradingScale;

    // fix ranges from back to front
    for (let i = cleanedUpGradingScale.length - 1; i >= 0; i--) {
        let gradeRange = cleanedUpGradingScale[i];

        if (i === 0 || cleanedUpGradingScale[i - 1].minScore > gradeRange.maxScore) {
            continue; // first element or okay
        }

        // fix overlapping range ___________________________________________
        // current max score overlaps with previous min score

        // attempt an easy fix: can we move max closer to min?
        if (gradeRange.maxScore - Scoring.POINTS_STEP >= gradeRange.minScore) {
            gradeRange.maxScore = gradeRange.maxScore - Scoring.POINTS_STEP;
            continue;
        }

        // elements to the right have already been cleaned up
        // --> find an element to the right that can make space for us
        const targetElementIndexRight = _findAdaptableRangeTarget(cleanedUpGradingScale, i, "RIGHT");

        if (targetElementIndexRight >= 0) {
            // there is such an element
            cleanedUpGradingScale = _redistributeScoresRight(cleanedUpGradingScale, i, targetElementIndexRight);
        } else {
            // there is no such element
            // -> find an element to the left that can make space for us
            const targetElementIndexLeft = _findAdaptableRangeTarget(cleanedUpGradingScale, i, "LEFT");

            if (targetElementIndexLeft >= 0) {
                // there is such an element
                // (if there is none, we cannot clean up this element and need to leave this up to the fallback)

                cleanedUpGradingScale = _redistributeScoresLeft(cleanedUpGradingScale, i - 1, targetElementIndexLeft);
            }
        }
    }

    return cleanedUpGradingScale;
};

const _validateGradingScale = (gradingScale: MinMaxGradingScale) => {
    const isError = gradingScale.some((gradeRange: MinMaxGradeRange, index: number) => {
        if (gradeRange.minScore > gradeRange.maxScore) return true; // min max error
        if (index > 0) {
            const gap = gradingScale[index - 1].minScore - gradeRange.maxScore;
            return gap !== Scoring.POINTS_STEP; // distribution error
        }
        return false; // no error found
    });

    return !isError;
};

export const convertToMinMaxGradingScale = (overallScore: number, gradingScale: PercentageGradingScale) => {
    const minMaxGradingScale = _createMinMaxFromPercentage(overallScore, gradingScale);

    const cleanedUpGradingScale = _cleanUpGradingScale(minMaxGradingScale);

    const isGradingScaleOk = _validateGradingScale(cleanedUpGradingScale);

    if (isGradingScaleOk) {
        return cleanedUpGradingScale;
    } else {
        // fallback to default grading scale
        const newMinMaxGradingScale = _createMinMaxFromPercentage(
            overallScore,
            getStandardOrDefaultConfiguration().settings.gradingAndSignatures.gradingScale.scale
        );

        return _cleanUpGradingScale(newMinMaxGradingScale);
    }
};
