import React, { useState } from "react";
import { BookContextProps } from "p6m-contexts";
import { useHistory } from "react-router-dom";
import {
    AvailableBandsInfo,
    GetBandInfoResponse,
    P6uBand,
    P6uPublisherBook,
    P6uSubject,
    RecentBook,
    RecentBooks,
} from "p6m-p6u";
import { getRequest } from "../helpers/networkHelper";
import { filterSearchBoxRequest } from "../helpers/TestCreation";
import { useTestCreationContext } from "./TestCreationContext";
import { useGeneralContext } from "./AppGeneralContext";
import { AxiosResponse } from "axios";
import { SourceType } from "../enums/sources";
import { useT } from "@transifex/react";

const BookContext = React.createContext<BookContextProps | undefined>(undefined);

function useBookContext() {
    const context = React.useContext(BookContext);

    if (!context) {
        throw new Error("useBookContext must be used inside the provider");
    }

    const { setIsDataBeingLoaded, creatingMode, setCreatingMode } = useGeneralContext();

    const {
        selectedBookData,
        setSelectedBookData,
        removeContentFromVocabularyDrawer,
        userOwnSubjects,
        setTestName,
        loadInitArticleIdData,
        openWarningModal,
    } = useTestCreationContext();

    const history = useHistory();

    const t = useT();
    const t_invalidArticleId = t("It was not possible to load the requested book.", {
        _tags: "CreateTest,SelectExerciseType",
    });

    const selectSubjectFilter =
        creatingMode === "RECURRING_TASK" ? ["sonstige"] : ["sonstige", "ancientgreek", "arabic", "chinese"];
    const selectPublisherFilter = creatingMode === "RECURRING_TASK" ? [] : ["lawbility", "hefei_huang_verlag"];
    const selectBookFilter = ["phase6_lrs"];

    const {
        recentlyUsedBooks,
        setRecentlyUsedBooks,
        setAvailableSubjects,
        setAvailablePublishersAndBooks,
        setAvailableBands,
        userUsedBooksInfo,
        setUserUsedBooksInfo,
        setSelectBandFilter,
        selectBandFilter,
    } = context;

    async function loadBookFromRecentBooks(articleId: string) {
        if (
            selectedBookData.band &&
            selectedBookData.band.ID &&
            parseInt(selectedBookData.band.ID) === parseInt(articleId)
        ) {
            history.push("/create/select-vocabulary");
            return;
        }

        setIsDataBeingLoaded(true);
        const book = recentlyUsedBooks[articleId];

        if (book) {
            setIsDataBeingLoaded(true);
            try {
                const bandInfoResponse = await getRequest("p6u/get-band-info/", { bandId: articleId });
                if (!bandInfoResponse || !bandInfoResponse.data) {
                    throw new Error("No data was loaded");
                }
                const bandData: GetBandInfoResponse = bandInfoResponse.data;
                setSelectedBookData({ ...bandData });

                history.push("/create/select-vocabulary");
                removeContentFromVocabularyDrawer("BOOK");
            } catch (e) {
            } finally {
                setIsDataBeingLoaded(false);
            }
        } else {
            alert("cant");
            setIsDataBeingLoaded(false);
        }
    }

    async function loadUserRecentBooks() {
        let recentBooks: RecentBooks = {};
        let recentBooksArray: Array<RecentBook> = [];

        try {
            const recentBooksResponse: AxiosResponse<Array<RecentBook>> = await getRequest("aea/recent-articles/");

            recentBooksArray = recentBooksResponse.data;
            recentBooksArray.sort((a, b) => (b.date || 0) - (a.date || 0));
            recentBooksArray = recentBooksArray.filter((t) => !!t.name);
            recentBooksArray = recentBooksArray.filter(
                (t) => !t.ownSubjectId || (t.ownSubjectId && userOwnSubjects.some((s) => s.id === t.ownSubjectId))
            );

            recentBooksArray.forEach((book) => {
                if (!book.articleId && !book.ownSubjectId) {
                    return;
                }
                recentBooks[(book.articleId || book.ownSubjectId)!] = {
                    ...book,
                };
            });
        } catch (e: any) {}

        setRecentlyUsedBooks({ ...recentBooks });
    }

    async function loadBooksInfoFromUUIDsIntoState(bookUuids: Array<string>) {
        setIsDataBeingLoaded(true);
        const booksInfo: AvailableBandsInfo = { ...userUsedBooksInfo };

        for (let i = 0; i < bookUuids.length; i++) {
            const bookUuid = bookUuids[i];
            if (booksInfo.hasOwnProperty(bookUuid) && booksInfo[bookUuid].band && booksInfo[bookUuid].band.ID) {
                continue;
            }
            try {
                const bandInfoResponse = await getRequest("p6u/get-band-info/", { bandId: bookUuid, isUuid: true });
                if (!bandInfoResponse || !bandInfoResponse.data) {
                    throw new Error("No data was loaded");
                }
                booksInfo[bookUuid] = bandInfoResponse.data;
            } catch (e) {}
        }
        setIsDataBeingLoaded(false);
        setUserUsedBooksInfo({ ...booksInfo });
    }

    function setContentSelectionFiltersForTest() {
        setSelectBandFilter([]);
    }

    function loadAvailableSubjects() {
        getRequest("p6u/get-subjects/").then((response) => {
            if (response.data) {
                // setUserInfo(response.data);
                const data: Array<any> = response.data;
                let subjects: Array<P6uSubject>;
                subjects = filterSearchBoxRequest(data, "ID", selectSubjectFilter);
                setAvailableSubjects(subjects);
            }
        });
    }

    function loadPublisherBooksForSubject(subject: string) {
        getRequest("p6u/get-subject-publishers-and-books/", { subject }).then((response) => {
            if (response.data) {
                const data: Array<any> = response.data;
                let publishersBooks: Array<P6uPublisherBook>;
                publishersBooks = filterSearchBoxRequest(data, "PublisherID", selectPublisherFilter);
                publishersBooks = filterSearchBoxRequest(publishersBooks, "BookID", selectBookFilter);
                setAvailablePublishersAndBooks(publishersBooks);
            }
        });
    }

    function loadBandsForBook(subject: string, publisherAndBookId: string) {
        const publisherAndBook = publisherAndBookId.split("*");

        if (!publisherAndBook[0] || !publisherAndBook[1]) {
            return;
        }

        getRequest("p6u/get-bands-for-book/", {
            subject,
            publisher: publisherAndBook[0],
            book: publisherAndBook[1],
        }).then((response) => {
            if (response.data) {
                const data: Array<any> = response.data;
                let bands = filterSearchBoxRequest(data, "UUID", selectBandFilter);
                bands = filterSearchBoxRequest(bands, "ID", selectBandFilter);
                setAvailableBands(bands);
            }
        });
    }

    async function checkForInitArticleId(defaultTestName: string) {
        const initArticleId = localStorage.getItem("initAID");

        if (initArticleId) {
            try {
                setTestName(defaultTestName);
                await loadInitArticleIdData(initArticleId);
                localStorage.removeItem("initAID");
                setCreatingMode("TEST");
                setContentSelectionFiltersForTest();
                setTimeout(() => {
                    history.push("/create/select-book");
                }, 500);
            } catch (e) {
                console.log(e);
                openWarningModal(t_invalidArticleId);
            }
        }
    }

    return {
        ...context,
        loadBookFromRecentBooks,
        loadUserRecentBooks,
        loadAvailableSubjects,
        loadPublisherBooksForSubject,
        loadBandsForBook,
        loadBooksInfoFromUUIDsIntoState,
        setContentSelectionFiltersForTest,
        checkForInitArticleId,
    };
}

function BookContextProvider(props: any) {
    const [availableSubjects, setAvailableSubjects] = useState<Array<P6uSubject>>([]);
    const [availablePublishersAndBooks, setAvailablePublishersAndBooks] = useState<Array<P6uPublisherBook>>([]);
    const [availableBands, setAvailableBands] = useState<Array<P6uBand>>([]);

    const [recentlyUsedBooks, setRecentlyUsedBooks] = useState<RecentBooks>({});

    const [selectBandFilter, setSelectBandFilter] = useState([]);

    // Exclusive vars for the vokabeltrainer exercises (recurring tasks)
    const [userUsedBooksInfo, setUserUsedBooksInfo] = useState<AvailableBandsInfo>({});
    const [lastSourceSelected, setLastSourceSelected] = useState<SourceType | undefined>(SourceType.BOOK);

    const value = {
        availableSubjects,
        setAvailableSubjects,
        availablePublishersAndBooks,
        setAvailablePublishersAndBooks,
        availableBands,
        setAvailableBands,
        recentlyUsedBooks,
        setRecentlyUsedBooks,
        selectBandFilter,
        setSelectBandFilter,
        userUsedBooksInfo,
        setUserUsedBooksInfo,
        lastSourceSelected,
        setLastSourceSelected,
    };

    return (
        <BookContext.Provider
            value={value}
            {...props}
        />
    );
}

export { BookContextProvider, useBookContext };
