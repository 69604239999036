import styled from "styled-components";
import PhaseSixIcon from "../phaseSixIcon/PhaseSixIcon";
import DesignConstants from "../../constants/DesignConstants";

export const Wrapper = styled.div<{ clickable?: boolean; underlined?: boolean; disabled?: boolean }>`
    cursor: ${({ disabled, clickable }) => (disabled ? "not-allowed" : clickable ? "pointer" : "default")};
    flex: 1;
    display: flex;
    align-items: center;
    gap: ${(underlined) => (underlined ? DesignConstants.SPACES.XS : DesignConstants.SPACES.S)};
`;

export const ContentWrapper = styled.div<{
    isFocused?: boolean;
    highlighted?: boolean;
    underlined?: boolean;
    isFilledIn?: boolean;
}>`
    flex: 1;
    display: flex;
    align-items: center;

    ${({ underlined }) => {
        if (underlined) {
            return `border-bottom: ${DesignConstants.BORDERS.THIN}`;
        } else {
            return `border: ${DesignConstants.BORDERS.MEDIUM}`;
        }
    }};
    border-color: ${({ isFocused, highlighted, underlined, isFilledIn }) => {
        if (highlighted) return DesignConstants.COLORS.P6_ORANGE;
        if (isFocused || (underlined && !isFilledIn)) return DesignConstants.COLORS.MID_GREY;
        return "transparent";
    }};
    border-radius: ${({ underlined }) => (underlined ? 0 : DesignConstants.BORDER_RADIUS.BUTTON)};

    padding: 0
        ${({ isFocused, underlined }) => {
            if (underlined) {
                if (isFocused) {
                    return DesignConstants.SPACES.XS;
                } else {
                    return 0;
                }
            } else {
                return DesignConstants.SPACES.S;
            }
        }};
    gap: ${DesignConstants.SPACES.S};

    transition: color 500ms ease-in, background 500ms ease-in;

    &.triggerFlash {
        color: ${DesignConstants.COLORS.P6_ORANGE};
        background: ${DesignConstants.COLORS.P6_ORANGE_LIGHT_PASTEL};
    }
`;

export const InputField = styled.input<{ fontSize?: string | number }>`
    flex: 1;
    display: flex;
    padding: ${DesignConstants.SPACES.XXS} 0;
    font-size: ${({ fontSize }) => fontSize ?? DesignConstants.FONT_SIZES.L};
    font-family: inherit;
    color: inherit;
    outline: none;
    border: none;
    background: transparent;

    ::placeholder {
        color: ${DesignConstants.COLORS.MID_GREY};
    }
`;

export const AlertWrapper = styled.div<{ disabled?: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${DesignConstants.SPACES.XXS};
    opacity: ${({ disabled }) => (disabled ? DesignConstants.OPACITY.HALF : 1)};
`;

export const AlertIcon = styled(PhaseSixIcon)`
    height: ${DesignConstants.SPACES.L};
    width: ${DesignConstants.SPACES.L};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${DesignConstants.FONT_SIZES.XL};
    color: ${DesignConstants.COLORS.P6_ORANGE};
`;

export const Text = styled.p<{ fontSize?: string; lightGrey?: boolean }>`
    font-size: ${({ fontSize }) => fontSize ?? DesignConstants.FONT_SIZES.L};
    font-family: inherit;
    padding: ${DesignConstants.SPACES.XXS} 0;
    ${({ lightGrey }) => lightGrey && `color: ${DesignConstants.COLORS.MID_GREY}`};
`;

export const ActionsWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: ${DesignConstants.SPACES.XS};
`;

export const StyledIcon = styled(PhaseSixIcon)<{ disabled: boolean; iconsize?: string }>`
    font-size: ${({ iconsize }) => iconsize ?? DesignConstants.FONT_SIZES.XXL};
    font-weight: ${DesignConstants.FONT_WEIGHTS.MEDIUM};
    opacity: ${({ disabled }) => disabled && DesignConstants.OPACITY.HALF};
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;
